import { jshellLoggerPropsLocalStorageKey } from '../../../config/localStorageKeys';
import {
  setItemLocalStorage,
  getItemLocalStorage,
  removeItemLocalStorage
} from '../../../utils/toBeRemoved/localStorage';
import { getJWeb, isNative, JWebEnums } from '../../../services/JWeb';
import { JWebType } from '../../../services/JWeb/types';
import * as T from './types';
import {
  createBareboneLogger,
  newInternalLogger
} from '../../../services/loggerService/createBareboneLogger';

/* internal variables */
let loggerInput: T.LoggerInputType;
let isNativeInfo: boolean;
let jweb: JWebType;

const types: T.EnumAllowedLogType[] = Object.values(T.EnumAllowedLogType);

let loggedMissingJWebPlugin = false;
const _consoleApi = (type: T.EnumAllowedLogType, arrayValue: any[]) => {
  const { disableBrowserConsoleOnNative } = _getLoggerOptions() || {};

  const isNativeLogPluginMissing = isNativeInfo && !jweb?.Plugins?.Console;
  const shouldReportMissingNativeLogPlugin =
    isNativeLogPluginMissing && !loggedMissingJWebPlugin;
  const shouldCallNativeLog = isNativeInfo && jweb?.Plugins?.Console;
  const shouldCallBrowserLog =
    !isNativeInfo || isNativeLogPluginMissing || !disableBrowserConsoleOnNative;

  if (shouldReportMissingNativeLogPlugin) {
    loggedMissingJWebPlugin = true;
    console.warn(
      'JWeb Console Plugin not found. Using default console instead. You may miss this log on some native apps.'
    );
  }

  if (shouldCallNativeLog) {
    const nativeLogArgs = (() => {
      const logLevel = (() => {
        switch (type) {
          case 'debug':
            return JWebEnums.LogLevel.Debug;
          case 'error':
            return JWebEnums.LogLevel.Error;
          case 'warn':
            return JWebEnums.LogLevel.Warn;
          case 'log':
            return JWebEnums.LogLevel.Log;
        }
      })();

      const message = (() => {
        let result = '';
        arrayValue?.forEach((value) => {
          if (typeof value === 'string') {
            result += ' ' + value;
          } else {
            try {
              result += ' ' + JSON.stringify(value);
            } catch (error) {
              console.error(error);
            }
          }
        });
        return result;
      })();

      return {
        logLevel,
        message,
        tag: ''
      };
    })();

    jweb?.Plugins?.Console?.log?.(nativeLogArgs);
  }

  if (shouldCallBrowserLog) {
    console?.[type]?.(...arrayValue);
  }
};

const _getLoggerOptions = (): T.LoggerInputType => {
  const loggerProps: T.LoggerInputType =
    getItemLocalStorage(jshellLoggerPropsLocalStorageKey) || loggerInput;

  return {
    ...loggerProps,
    enable: loggerProps?.enable === false ? false : true,
    allowedTypes: {
      ...loggerProps?.allowedTypes,
      error: loggerProps?.allowedTypes?.error === false ? false : true
    }
  };
};

const _createLogger: T.CreateLoggerType = ({ type, preffixLog }) => {
  return (...args) => {
    const {
      allowedTypes,
      allowedExpressions,
      enable,
      forceAllowedExpressionsForErrors
    } = _getLoggerOptions();

    const isValidExpressions = (
      ...args: Parameters<T.LogFuncType>
    ): boolean => {
      const shouldIgnoreAllowedExpressions =
        type === T.EnumAllowedLogType.error &&
        !forceAllowedExpressionsForErrors;

      const shouldCheckAllowedExpressions =
        !shouldIgnoreAllowedExpressions && allowedExpressions;

      if (shouldCheckAllowedExpressions) {
        return allowedExpressions.some((expression) =>
          args.some((arg) => {
            return typeof arg === 'string' && new RegExp(expression).test(arg);
          })
        );
      }
      return true;
    };

    const logs = [...args];
    if (preffixLog) logs.unshift(preffixLog);

    if (enable) {
      try {
        if (isValidExpressions(...logs) && allowedTypes?.[type]) {
          _consoleApi(type, logs);
        }
      } catch (err) {
        if (allowedTypes?.error) {
          const withPreffixLog = preffixLog ? preffixLog + ' = ' : '';
          console.error(withPreffixLog + err.toString());
        }
      }
    }
  };
};

export const createLoggerInstance: T.CreateLoggerInstanceType = (options) => {
  const loggers: T.LoggerType = {} as any;

  types?.forEach((type) => {
    loggers[type] = _createLogger({ ...options, type });
  });
  return loggers;
};

const setLocalOptions: T.SetLocalOptionsType = (options) => {
  setItemLocalStorage(jshellLoggerPropsLocalStorageKey, options);
};

const removeLocalOptions: T.RemoveLocalOptionsType = () => {
  removeItemLocalStorage(jshellLoggerPropsLocalStorageKey);
};

const publicMethods = {
  createLoggerInstance,
  setLocalOptions,
  removeLocalOptions
};

const defaultLoggerInstance: T.LoggerType = {
  ...createLoggerInstance(),
  ...publicMethods
};
// Create a barebone logger to be used before the full load.
createBareboneLogger();
export const internalLogger = newInternalLogger;
/*
export const internalLogger = createLoggerInstance({
  preffixLog: 'JSHELL__@jarvis__shell-commons'
});
*/

export default async function logger(
  loggerDataInput: T.LoggerInputType
): Promise<T.LoggerType> {
  loggerInput = loggerDataInput;
  jweb = await getJWeb();
  isNativeInfo = await isNative();
  // We override the logger options in the local storage
  setLocalOptions(loggerDataInput);

  return defaultLoggerInstance;
}

export function createNoopLoggerInterface(): T.LoggerType {
  const noOpLogs = {
    debug: () => {},
    error: () => {},
    warn: () => {},
    log: () => {}
  };

  return {
    ...noOpLogs,
    createLoggerInstance: function (): Record<T.EnumAllowedLogType, any> {
      console.debug('Function not implemented.');
      return noOpLogs;
    },
    setLocalOptions: function (): void {
      console.debug('Function not implemented.');
    },
    removeLocalOptions: function (): void {
      console.debug('Function not implemented.');
    }
  };
}
