import bindAllMethods from '../../utils/bindAllMethods';
import { Stack } from '../../types/stratus';
import { ApplicationContextEnum } from './enums';

export type BaseApplicationServiceParams = {
  clientId: string;
  appName: string;
  portalStack: Stack;
  applicationContext: ApplicationContextEnum;
};

export default class BaseApplicationService {
  private _clientId: string;
  private _appName: string;
  private _portalStack: Stack;
  private _applicationContext: ApplicationContextEnum;

  constructor({
    clientId,
    appName,
    portalStack,
    applicationContext
  }: BaseApplicationServiceParams) {
    this._clientId = clientId;
    this._appName = appName;
    this._portalStack = portalStack;
    this._applicationContext = applicationContext;
    bindAllMethods(this);
  }

  public getApplicationContext(): ApplicationContextEnum {
    return this._applicationContext;
  }

  public getClientId(): string {
    return this._clientId;
  }

  public getAppName(): string {
    return this._appName;
  }

  public getPortalStack(): Stack {
    return this._portalStack;
  }
}
