import { UserOnboardingJoinInvitedOrganizationParams } from '../../../services/userOnboardingService/types';
import { IUserOnboardingService } from '../../../services/userOnboardingService';
import bindAllMethods from '../../../utils/bindAllMethods';
import { IUserService } from '../../../services/userService';
import { AuthenticationDataType, UserDataInterfaceV1Type } from './types';
import TenantObserver, {
  TenantEvents
} from '../../../services/tenantHandler/TenantObserver';
import { HandledTenant } from '../../../services/tenantHandler/types';

// TODO: Make the own interface UserDataType
export type UserInterfaceV1Type = {
  _userData: {
    get(): Promise<UserDataInterfaceV1Type>;
  };
  onboarding: {
    isUserOnboarded(): Promise<boolean>;
    joinInvitedOrganization(
      options?: UserOnboardingJoinInvitedOrganizationParams
    ): Promise<void>;
    onboardUser(): Promise<void>;
    shouldRenderOnboardingScreen(): Promise<boolean>;
  };
  getUserData(): Promise<UserDataInterfaceV1Type>;
  useAuthenticationData(React: any): AuthenticationDataType;
};

type UserInterfaceV1Param = {
  userOnboardingService: IUserOnboardingService;
  userService: IUserService;
};

class UserInterfaceV1 {
  private _userOnboardingService: IUserOnboardingService;
  private _userService: IUserService;

  constructor({ userOnboardingService, userService }: UserInterfaceV1Param) {
    this._userOnboardingService = userOnboardingService;
    this._userService = userService;
    bindAllMethods(this);
  }

  public getInterface(): UserInterfaceV1Type {
    return {
      // For internal use only
      _userData: { get: () => this._userService.get() },
      onboarding: {
        isUserOnboarded: () => this._userOnboardingService.isUserOnboarded(),
        joinInvitedOrganization: () =>
          this._userOnboardingService.joinInvitedOrganization(),
        onboardUser: () => this._userOnboardingService.onboardUser(),
        shouldRenderOnboardingScreen: () =>
          this._userOnboardingService.shouldRenderOnboardingScreen()
      },
      getUserData: () => this._userService.get(),
      useAuthenticationData: this.useAuthenticationData
    };
  }

  public useAuthenticationData(React: any): AuthenticationDataType {
    const authenticationData = this._userService.getAuthenticationData();
    const [result, setResult] = React.useState(authenticationData);

    const setTenantData = (_: HandledTenant[]) => {
      const authData = this._userService.getAuthenticationData();
      setResult(authData);
    };

    React.useEffect(() => {
      TenantObserver.subscribe(TenantEvents.SET_TENANT, setTenantData);
      return () =>
        TenantObserver.unsubscribe(TenantEvents.SET_TENANT, setTenantData);
    }, [result]);

    return result;
  }
}

export default UserInterfaceV1;
