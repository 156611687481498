import {
  getItemLocalStorage,
  removeItemLocalStorage,
  setItemLocalStorage
} from '../../../utils/toBeRemoved/localStorage';
import * as T from '../types';
import { container } from 'tsyringe';

export const jshellLoggerPropsLocalStorageKey = 'jshellLoggerProps';

// TODO:In the future, this need to be changed to a Repository
export const _getLoggerOptions = (): T.LoggerInputType => {
  const manifestLoggerInput =
    container?.resolve<T.LoggerInputType>('LoggerProps');

  const loggerProps: T.LoggerInputType =
    getItemLocalStorage(jshellLoggerPropsLocalStorageKey) ||
    manifestLoggerInput;

  return {
    ...loggerProps,
    enable: loggerProps?.enable ?? true,
    allowedTypes: {
      ...loggerProps?.allowedTypes,
      error: loggerProps?.allowedTypes?.error ?? true
    }
  };
};

export const _setLocalOptions: T.SetLocalOptionsType = (options) => {
  const fixedOptions = {
    ...options,
    allowedTypes: {
      debug: false,
      error: false,
      log: false,
      warn: false,
      ...options?.allowedTypes
    }
  };
  setItemLocalStorage(jshellLoggerPropsLocalStorageKey, fixedOptions);
};

export const _removeLocalOptions: T.RemoveLocalOptionsType = () => {
  removeItemLocalStorage(jshellLoggerPropsLocalStorageKey);
};
