import { ILevelLogClass, LevelLogProps } from '../types';
import { genericLogMethod } from '../utils/genericLogMethod';
import { errorDictionary } from '../Dictionaries/ErrorDictionary';
import * as T from '../types';
import { ErrorLoggerType } from '../Dictionaries/types/types';

class ErrorLogger implements ILevelLogClass {
  private _errorLogs: T.FunctionWithLogMethods = {} as T.FunctionWithLogMethods;

  constructor(loggerProps: LevelLogProps) {
    // default log
    const _defaultLog = (...value) => this._errorLogs['e0'](...value);

    errorDictionary.forEach((logMethods) => {
      const _method = genericLogMethod({
        ...logMethods,
        loggerProps,
        type: 'error',
        rootPrintOnlyValues: logMethods.id === 'e0',
        // Setting the values from Initialization
        preffixLog: loggerProps?.preffixLog,
        serviceName: loggerProps.serviceName,

        // Setting the default values from the ApplicationService
        appName: loggerProps.appName,
        applicationContext: loggerProps.applicationContext,
        envStack: loggerProps.envStack,
        authenticationProvider: loggerProps.authenticationProvider
      });
      _defaultLog[logMethods.id] = _method;
    });

    this._errorLogs = _defaultLog as unknown as T.FunctionWithLogMethods;
  }

  getLogs(): ErrorLoggerType {
    return this._errorLogs as ErrorLoggerType;
  }
}
export default ErrorLogger;
