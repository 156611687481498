import bindAllMethods from '../../../utils/bindAllMethods';
import AuthContextEnum from '../../authTokenService/AuthContextEnum';
import type IRefreshTokenService from './IRefreshTokenService';
import { RefreshParameterType } from '../ISessionService';
import { Lifecycle, inject, injectable, scoped } from 'tsyringe';
import { internalLogger } from '../../../interface/v1/logger';
import { TenantStrategyEnum } from '../../tenantHandler/strategy/TenantStrategyEnum';
import IExchangeTokenService from '../exchangeTokenService/IExchangeTokenService';
import ExchangeTokenServiceNative from '../exchangeTokenService/ExchangeTokenServiceNative';
import { getJWeb } from '../../JWeb';
import * as JWebTypes from '../../JWeb/types';
import { TokenType } from '../../JWeb/JWebEnums';
import { AuthTokenService, IAuthTokenService } from '../../authTokenService';
import getConfigIdBasedOnStack from '../utils/getConfigIdBasedOnStack';
import IApplicationService from '../../../services/applicationService/IApplicationService';
import { ApplicationContextEnum } from '../../../services/applicationService/enums';
import type { AuthProvidersPropsType } from '../../../infra/commonInitializer/types';
import AuthenticationProviderEnum from '../../../config/authenticationProviderEnum';

@injectable()
@scoped(Lifecycle.ContainerScoped)
class RefreshTokenServiceNative implements IRefreshTokenService {
  private _refreshPromise: Promise<void>;
  private _authTokenService: IAuthTokenService;
  private _exchangeTokenServiceNative: IExchangeTokenService;
  private _applicationService: IApplicationService;
  private _authProviderProps: AuthProvidersPropsType;

  constructor(
    @inject(AuthTokenService)
    authTokenService: IAuthTokenService,
    @inject(ExchangeTokenServiceNative)
    exchangeTokenServiceNative: IExchangeTokenService,
    @inject('ApplicationService') applicationService: IApplicationService,
    @inject('AuthProviderProps')
    authProviderProps: AuthProvidersPropsType
  ) {
    this._authTokenService = authTokenService;
    this._exchangeTokenServiceNative = exchangeTokenServiceNative;
    this._applicationService = applicationService;
    this._authProviderProps = authProviderProps;
    bindAllMethods(this);
  }

  private getAdditionalParamsForJwebGetToken() {
    return {
      additionalAuthorizationParameters: {
        config_id: getConfigIdBasedOnStack(
          this._applicationService.getPortalStack()
        )
      }
    };
  }

  public async refresh(
    refreshParameterType?: RefreshParameterType
  ): Promise<void> {
    internalLogger.log('Refresh token', refreshParameterType?.tenantsIdMap);

    if (this._refreshPromise) return this._refreshPromise;

    const refreshAction = async () => {
      //TODO Fix why the setStratusAccessTokenFromNative is needed then remove this from here
      const authContext = await this._setStratusAccessTokenFromNative();

      // @TODO check if this is needed to pass for the exchange
      const tenantId = this._authTokenService.getTenantIdFromToken(authContext);

      if (!this._checkIfWebTokenIsRequired()) {
        this._exchangeNativeTokenIntoWebToken(tenantId, authContext);
      }
    };

    this._refreshPromise = refreshAction().finally(() => {
      this._refreshPromise = undefined;
    });

    return this._refreshPromise;
  }

  private _checkIfWebTokenIsRequired(): boolean {
    const isNativeAppStratus =
      this._authProviderProps.initialAuthenticationProvider ===
      AuthenticationProviderEnum.authz;
    const isManifestStratus =
      this._authProviderProps.authenticationProvider ===
      AuthenticationProviderEnum.authz;
    return (
      isNativeAppStratus &&
      isManifestStratus &&
      this._applicationService.getApplicationContext() ===
        ApplicationContextEnum.NATIVE_PORTAL
    );
  }

  private async _exchangeNativeTokenIntoWebToken(
    tenantId: string,
    authContext: AuthContextEnum
  ): Promise<void> {
    await this._exchangeTokenServiceNative.exchange({
      tenantId: tenantId || null,
      authContext,
      tenantStrategy: TenantStrategyEnum.stratusNative,
      jwebTokenOptions: {
        tokenType: TokenType.user,
        requireFreshToken: true
      }
    });
  }

  private async _setStratusAccessTokenFromNative(): Promise<AuthContextEnum> {
    try {
      const Auth = await getJWeb()?.then?.((res) => res?.Plugins?.Auth);
      const tokenProviderOptions = {
        tokenType: TokenType.user,
        requireFreshToken: true,
        ...this.getAdditionalParamsForJwebGetToken()
      } as JWebTypes.TokenProviderOptions;

      const { tokenValue } = (await Auth?.getToken?.({
        tokenProviderOptions
      })) as JWebTypes.AccessToken;

      const haveAccessToken =
        typeof tokenValue === 'string' && tokenValue.length > 1;

      if (haveAccessToken) {
        const isTenantToken =
          !!this._authTokenService.getTenantIdFromGivenToken(tokenValue);

        const authContext = isTenantToken
          ? AuthContextEnum.tenant
          : AuthContextEnum.tenantless;

        this._authTokenService.setToken(tokenValue, authContext);
        return authContext;
      } else {
        this._authTokenService.deleteToken(AuthContextEnum.tenantless);
      }
    } catch (error) {
      this._authTokenService.deleteToken(AuthContextEnum.tenantless);
      internalLogger.error('Error on getToken');
      console.error(error);
      return;
    }
    return AuthContextEnum.tenantless;
  }
}

export default RefreshTokenServiceNative;
