import {
  DEFAULT_PAGINATION_PARAM_LIMIT,
  DEFAULT_PAGINATION_PARAM_OFFSET
} from '../../../clients/oneCloud/relationshipMgt/constants';
import OneCloudAggregationSvcApiClient from '../../../clients/oneCloud/relationshipMgt/OneCloudAggregationSvcApiClient';
import { OneCloudAggregationAPIRoleOrganizationListResponseDataType } from '../../../clients/oneCloud/relationshipMgt/types';
import { OneCloudAPIDefaultRequestParams } from '../../../clients/oneCloud/types';
import { IAuthProviderService } from '../../authProviderService';
import { AuthContextEnum } from '../../authTokenService';
import { ISessionService } from '../../session';
import TenantFilterBuilder from '../tenantFilterBuilder/TenantFilterBuilder';
import TenantUtils from '../TenantUtils';
import { TenantStrategyEnum } from './strategy';
import TenantStrategy from './TenantStrategy';
import * as T from './types';

type OrganizationProperties = {
  fallback?: string;
  filter?: {
    role: 'admin' | 'user' | 'all';
    tenantType: string[];
  };
  customBehaviors?: [
    {
      filter?: {
        role: 'admin' | 'user' | 'all';
        tenantType: string[];
      };
      onSelect: {
        redirectTo?: string;
      };
    }
  ];
};

export default class OnecloudOrganizationTenantHandler
  implements TenantStrategy
{
  private _strategy = TenantStrategyEnum.onecloudOrganization;
  private _options: OrganizationProperties;
  private _assetReference: string;
  private _oneCloudAggregationSvcClient: OneCloudAggregationSvcApiClient;
  private _sessionService: ISessionService;
  private _cachedPaginatedTenantList: T.PaginatedTenantVisualizationCacheType[] =
    [];
  private _authProvider: IAuthProviderService;

  _stack: T.StackType;

  constructor({
    stack,
    options,
    assetReference,
    sessionService,
    authProvider
  }: T.TenantHandlerStrategyType) {
    this._stack = stack;

    this._authProvider = authProvider;
    this._options = options;
    this._assetReference = assetReference;
    this._sessionService = sessionService;

    this._oneCloudAggregationSvcClient = new OneCloudAggregationSvcApiClient(
      OneCloudAggregationSvcApiClient.getBaseUrl(this._stack),
      this._authProvider
    );
  }

  public getTenantById = async (
    tenantId: string
  ): Promise<T.TenantVisualizationType> => {
    const tenantsPaginatedLists = this._cachedPaginatedTenantList.map(
      (paginations) => paginations.tenants
    );
    let tenant = tenantsPaginatedLists
      ?.flat()
      ?.find((tenant) => tenant.id === tenantId);

    // If the tenant is not found in the cached list, we will try to to fetch it
    // again from the API, as a recovery strategy. This is useful when the
    // tenant data isn't found because it was added during the JSHELL execution
    // and, thus, the cached list was not updated.
    if (!tenant) {
      const tenantList = await this.getTenantList(true);

      tenant = tenantList.find((tenant) => tenant.id === tenantId);
    }

    return tenant;
  };

  public async getTenantList(
    refresh?: boolean
  ): Promise<T.TenantVisualizationType[]> {
    const tenantsCachedLists = refresh
      ? []
      : this._cachedPaginatedTenantList.map(
          (paginations) => paginations.tenants
        );

    let tenants: T.TenantVisualizationType[];
    if (!tenantsCachedLists.length) {
      const paginatedTenantList = await this.getPaginatedTenantList({
        page: 0,
        refresh: refresh
      });
      tenants = paginatedTenantList?.tenants;
    } else {
      tenants = tenantsCachedLists?.flat();
    }

    const { filter } = this._options;
    const filteredTenantList = this.getFilteredTenantList(filter, tenants);
    const customBehaviorsList = this._options?.customBehaviors;
    if (customBehaviorsList) {
      let unifiedTenantList = filteredTenantList;
      unifiedTenantList = this.addCustomBehaviorsTenantList(
        customBehaviorsList,
        unifiedTenantList,
        tenants
      );
      return unifiedTenantList;
    }
    //TODO console.warn to indicate that this list have only the nodes that were found by the pagination one
    return filteredTenantList;
  }

  public async getPaginatedTenantList(
    options: T.GetPaginatedTenantListOptionsType
  ): Promise<T.PaginatedTenantVisualizationType> {
    let result: T.PaginatedTenantVisualizationType;
    result = this._cachedPaginatedTenantList.find(
      //TODO: analyze semantic of currentPage at cached tenant list
      (tenantPage) => tenantPage.currentPage === options.page
    );
    if (options.refresh || !result || result?.tenants?.length === 0) {
      result = await this._getPaginatedTenantListData(options);
      this._cachedPaginatedTenantList.push({
        parentTenantId: options.tenantId,
        ...result
      });
    }
    return result;
  }

  public getAssetReference(): string {
    return this._assetReference;
  }

  public getStrategy = (): TenantStrategyEnum => {
    return this._strategy;
  };

  public setTenant = async (
    id: string,
    authContext: AuthContextEnum
  ): Promise<void> => {
    await this._sessionService.exchangeToken(
      id,
      authContext,
      TenantStrategyEnum.onecloudOrganization
    );
  };

  public async _getPaginatedTenantListData(
    options: T.GetTenantListOptionsType
  ): Promise<T.PaginatedTenantVisualizationType> {
    const { page, paginationSize } = options;
    const _options: OneCloudAPIDefaultRequestParams = {};
    if (page || paginationSize) {
      const limit = paginationSize || DEFAULT_PAGINATION_PARAM_LIMIT;
      const offset = limit * page || DEFAULT_PAGINATION_PARAM_OFFSET;
      _options.params = {
        limit,
        offset
      };
    }
    const personOrgs = await this._oneCloudAggregationSvcClient.listPersonOrgs(
      _options
    );

    const tenants = personOrgs?.role_orgs?.map((tenantData) =>
      TenantUtils._convertOnecloudOrgToTenantVisualizationType(tenantData)
    );

    const { totalPages, currentPage } =
      this._convertCoptorPaginationResponseIntoTenantPagination(personOrgs);

    return {
      totalPages,
      currentPage,
      tenants
    };
  }

  private getFilteredTenantList(
    filter: T.TenantHandlerFilterType,
    tenants: T.TenantVisualizationType[]
  ) {
    return new TenantFilterBuilder({
      filter,
      tenants
    })
      .filterByRegexTenantType()
      .filterByRole()
      .filterByWhiteList()
      .getTenantList();
  }

  private _convertCoptorPaginationResponseIntoTenantPagination(
    personOrgs: OneCloudAggregationAPIRoleOrganizationListResponseDataType
  ): { totalPages: number; currentPage: number } {
    let currentPage = 0;
    let totalPages = 0;
    try {
      const {
        current_limit: currentLimit,
        current_offset: currentOffset,
        db_total: dbTotal
      } = personOrgs.params;

      currentPage = Math.floor(
        parseInt(currentOffset) / parseInt(currentLimit)
      );
      totalPages = Math.floor(parseInt(dbTotal) / parseInt(currentLimit));
    } catch (error) {
      console.error('Bad formatted pagination response.');
    }
    return { currentPage, totalPages };
  }

  public getAuthProvider(): IAuthProviderService {
    return this._authProvider;
  }

  public isRequiredToNavigate = (): boolean => {
    return true;
  };

  public getFallback = (): string => {
    return this?._options?.fallback;
  };

  isTenantValid(
    filter: T.TenantHandlerFilterType,
    tenant: T.TenantVisualizationType
  ): boolean {
    return this.getFilteredTenantList(filter, [tenant])?.length > 0;
  }

  private addCustomBehaviorsTenantList(
    customBehaviorsList,
    tenantList: T.TenantVisualizationType[],
    tenants: T.TenantVisualizationType[]
  ) {
    if (customBehaviorsList && tenantList && tenants) {
      for (const customBehaviors in customBehaviorsList) {
        const filterCustomBehavior =
          customBehaviorsList[customBehaviors].filter;

        const filteredCustomBehaviors = this.getFilteredTenantList(
          filterCustomBehavior,
          tenants
        );

        tenantList = this.mergeListsAndRemoveDuplicates(
          tenantList,
          filteredCustomBehaviors
        );
      }
    }
    return tenantList;
  }

  private mergeListsAndRemoveDuplicates(firstList, secondList) {
    const uniqueSet = new Set(
      [...firstList, ...secondList].map((obj) => JSON.stringify(obj))
    );

    const uniqueList = Array.from(uniqueSet).map((objString) =>
      JSON.parse(objString)
    );

    return uniqueList;
  }
}
