import { TenantHandlerParamsType } from '../../services/tenantHandler/strategy/external-types';
import { JWebErrorHandler, getJWeb } from '../../services/JWeb';
import { AccessToken } from '../../services/JWeb/types';
import getAuthenticationProviderByToken from '../../utils/tokenUtils/getAuthenticationProviderByToken';
import { strategyMapping } from './strategyMapping';
import AuthenticationProviderEnum from '../../config/authenticationProviderEnum';
import { TokenType } from '../../services/JWeb/JWebEnums';

const proccessWebManifestIntoAppManifest = async (
  manifest: Record<string, any>
): Promise<void> => {
  const JWeb = await getJWeb();
  const isLoggedIn = await JWeb?.Plugins?.Auth?.isLoggedIn?.();
  if (!isLoggedIn) return;

  const appToken = JWebErrorHandler<AccessToken>(
    // TODO: Check if it needs arguments
    await JWeb?.Plugins?.Auth?.getToken?.({
      tokenProviderOptions: {
        tokenType: TokenType.user
      }
    })
  );

  if (!appToken?.tokenValue) return;

  const appAuthenticationProvider = getAuthenticationProviderByToken(
    appToken.tokenValue
  );

  // TODO: Need to update the initialAuthenticationProvider and the authenticationProvider variables names to be more specific and update the application to follow and look for the new names.

  // If the manifest does not have an initialAuthenticationProvider (Portal), set it to the current authentication provider.
  if (!manifest?.services?.login?.initialAuthenticationProvider) {
    manifest.services.login = {
      initialAuthenticationProvider:
        manifest?.services?.login?.authenticationProvider ||
        AuthenticationProviderEnum.authz,
      ...manifest?.services.login
    };
  }

  manifest.services.login.authenticationProvider = appAuthenticationProvider;

  // Override each strategy based on the current auth provider and tenant strategy.
  manifest?.services?.tenantHandler?.tenantHandlerList?.forEach(
    (config: TenantHandlerParamsType) => {
      const newStrategy =
        strategyMapping[appAuthenticationProvider]?.[config.strategy];

      if (newStrategy) {
        config.strategy = newStrategy || config.strategy;
      }
    }
  );
};

export default proccessWebManifestIntoAppManifest;
