import {
  GetProviderListParam,
  GetProviderListResponseType
} from '../../clients/shell/provider';
import { SetServiceDependencies } from '../../infra/commonInitializer/types';
import { AuthContextEnum } from '../authTokenService';
import { AuthPluginError, AccessToken } from '../JWeb/types';
import { TenantStrategyEnum } from '../tenantHandler/strategy/TenantStrategyEnum';
import ISessionService, {
  LogoutOptionsType,
  RefreshParameterType,
  SignInOptionsType,
  SignInResponseType
} from './ISessionService';
import { GenerateAuthenticationUrlParams, ILoginService } from './loginService';
import { CriticalScopeHandler } from './CriticalScopeHandler';
import { BaseSessionServicePropsType } from './types';
import bindAllMethods from '../../utils/bindAllMethods';

export default abstract class BaseSessionService implements ISessionService {
  protected _criticalScopeHandler: CriticalScopeHandler;
  protected _loginService: ILoginService;

  constructor(options: BaseSessionServicePropsType) {
    this._criticalScopeHandler = new CriticalScopeHandler(
      { isNative: options.isNative },
      options.authTokenService
    );
  }

  abstract init(): Promise<void>;

  setDependencies(options: SetServiceDependencies): void {
    this._loginService = options?.services?.loginService;
    bindAllMethods(this);
  }

  abstract isLoggedIn(): boolean;

  abstract refresh(refreshParameterType?: RefreshParameterType);

  abstract exchangeToken(
    tenantId?: string,
    authContext?: AuthContextEnum,
    tenantStrategy?: TenantStrategyEnum
  ): Promise<void>;

  abstract clearSession(): Promise<void>;

  abstract redirectToIdentityProvider(): Promise<AuthPluginError | AccessToken>;

  abstract getIdToken(): string;

  abstract logout(options?: LogoutOptionsType): Promise<void>;

  getProviderList(
    options?: GetProviderListParam
  ): Promise<GetProviderListResponseType> {
    return this._loginService.getProviderList(options);
  }

  abstract generateAuthenticationUrl(
    options: GenerateAuthenticationUrlParams
  ): Promise<string>;

  isCriticalScopesValid(): boolean {
    return this._criticalScopeHandler.isCriticalScopesValid();
  }

  abstract getLoginCooldown(): number;

  abstract goForceLogin(waitCallback?: () => void): void;

  abstract signIn(options?: SignInOptionsType): Promise<SignInResponseType>;
}
