import { INavigationService } from '../../services/navigationService';
import { deleteCookie } from '../../utils/cookies';
import { getServices } from '../../infra/commonInitializer';
import { ILoginService } from '../../services/session/loginService';

export default class RemoveMaxAgeCookieInLoggedIn {
  private _loginService: ILoginService;

  constructor() {
    const { loginService } = getServices();
    this._loginService = loginService;
  }

  public init(): void {
    if (this._loginService.isLoggedinPath()) {
      deleteCookie('jarvisreactshelllogin__setMaxAge0OnLogin');
    }
  }
}
