import UserInterfaceV1, { UserInterfaceV1Type } from './UserInterfaceV1';
import { getServices } from '../../../infra/commonInitializer';
import { AuthenticationDataType, UserDataInterfaceV1Type } from './types';

let userInterfaceV1: UserInterfaceV1;

export function getUserInterfaceV1(): UserInterfaceV1 {
  return userInterfaceV1;
}

export default async function initializeUserInterfaceV1(): Promise<UserInterfaceV1Type> {
  if (userInterfaceV1) return userInterfaceV1.getInterface();
  const services = getServices();
  const { userOnboardingService, userService } = services;

  userInterfaceV1 = new UserInterfaceV1({
    userOnboardingService,
    userService
  });

  return userInterfaceV1.getInterface();
}

export function createNoopUserInterfaceInterface(): UserInterfaceV1Type {
  return {
    getUserData: function (): Promise<UserDataInterfaceV1Type> {
      console.debug('Function not implemented.');
      return Promise.resolve(null);
    },
    _userData: {
      get: function (): Promise<UserDataInterfaceV1Type> {
        console.debug('Function not implemented.');
        return Promise.resolve(null);
      }
    },
    useAuthenticationData: function (React: any): AuthenticationDataType {
      console.debug('Function not implemented.');
      return {
        isLoggedIn: false,
        userData: null
      };
    },
    onboarding: {
      isUserOnboarded: function (): Promise<boolean> {
        console.debug('Function not implemented.');
        return Promise.resolve(false);
      },
      joinInvitedOrganization: function (): Promise<void> {
        console.debug('Function not implemented.');
        return Promise.resolve();
      },
      onboardUser: function (): Promise<void> {
        console.debug('Function not implemented.');
        return Promise.resolve();
      },
      shouldRenderOnboardingScreen: function (): Promise<boolean> {
        console.debug('Function not implemented.');
        return Promise.resolve(false);
      }
    }
  };
}
