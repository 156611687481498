import { IUserOnboardingService } from '../../services/userOnboardingService';
import { INavigationService } from '../../services/navigationService';
import bindAllMethods from '../../utils/bindAllMethods';
import { internalLogger } from '../../interface/v1/logger';

type PropsType = {
  navigation: INavigationService;
  userOnboardingService: IUserOnboardingService;
};

export default class ForceReloadAfterUserOnboardingPath {
  private _navigation: INavigationService;
  private _userOnboardingService: IUserOnboardingService;
  private _shouldForceReloadWhenLeave: boolean;

  constructor({ navigation, userOnboardingService }: PropsType) {
    this._navigation = navigation;
    this._userOnboardingService = userOnboardingService;
    bindAllMethods(this);
  }

  private onboardingPathChangeHandler(
    location: INavigationService['location']
  ) {
    this._shouldForceReloadWhenLeave =
      this._userOnboardingService.isUserOnboardingPath(location.pathname);
  }

  private postOnboardingBlockerPushStateHandler(
    location: INavigationService['location']
  ) {
    const allowNavigation = true;

    // should force reload if user entered in an user onboarding path and then navigated to another path
    if (
      this._shouldForceReloadWhenLeave &&
      !this._userOnboardingService.isUserOnboardingPath(location.pathname)
    ) {
      internalLogger.debug(
        'Leaving user onboarding page. Will reload the page.'
      );
      this._navigation.redirect(location.pathname);
    }

    return allowNavigation;
  }

  init(): void {
    this._shouldForceReloadWhenLeave = false;
    this._navigation.listen(this.onboardingPathChangeHandler);
    this._navigation.block(this.postOnboardingBlockerPushStateHandler as any);

    this.onboardingPathChangeHandler(this._navigation.location);
  }
}
