export const defaultLogParams = [
  'preffixLog',
  'timestamp',
  'title',
  'level',
  'serviceName',
  'message'
];

export const defaultDebugParams = [
  'version',
  'applicationContext',
  'appName',
  'authenticationProvider',
  'envStack', // the word stack is also used in error, so we use envStack here
  'className',
  'methodName'
];
