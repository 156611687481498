import { getServices } from '../../../infra/commonInitializer';
import { ILoggerService } from '../../../services/loggerService';
import { NewLoggerInterfaceType } from './types';

class NewLoggerInterface {
  private _loggerService: ILoggerService;

  constructor() {
    const services = getServices();
    this._loggerService = services.loggerService;
  }

  getInterface(): NewLoggerInterfaceType {
    return {
      createLoggerInstance: this._loggerService.createLoggerInstance,
      setLocalOptions: this._loggerService.setLocalOptions,
      removeLocalOptions: this._loggerService.removeLocalOptions,
      log: this._loggerService.infoLogger,
      debug: this._loggerService.debugLogger,
      warn: this._loggerService.warnLogger,
      error: this._loggerService.errorLogger
    };
  }
}

export default NewLoggerInterface;
