import { ServiceLocalizationFallbackType } from '../types';
import { AbstractLocaleHandler } from './AbstractLocaleHandler';

export class DefaultLocaleHandler extends AbstractLocaleHandler {
  private _ignoreCountryFallback: boolean;
  constructor(
    languagesFallback: ServiceLocalizationFallbackType,
    ignoreCountryFallback: boolean
  ) {
    super(languagesFallback);
    this._ignoreCountryFallback = ignoreCountryFallback;
  }

  public handle(isLanguage: boolean, currentList: string[]): string {
    if (this._ignoreCountryFallback) {
      return;
    }
    // Retrieve the first element for language or country.
    return currentList?.[0];
  }
}
