import bindAllMethods from '../../utils/bindAllMethods';
import { Stack } from '../../types/stratus';
import BaseApplicationService from './BaseApplicationService';
import IApplicationService from './IApplicationService';
import { ApplicationServiceParams } from './types';
import AuthenticationProviderEnum from '../../config/authenticationProviderEnum';

export default class OnecloudApplicationService
  extends BaseApplicationService
  implements IApplicationService
{
  constructor({
    portalStack,
    clientId,
    appName,
    applicationContext
  }: ApplicationServiceParams) {
    super({ clientId, appName, portalStack, applicationContext });
    bindAllMethods(this);
  }

  public getAuthenticationProvider = () => AuthenticationProviderEnum.coptor;

  public getAuthStack(): Stack {
    switch (super.getPortalStack()) {
      case Stack.dev:
        return Stack.stage;
      case Stack.pie:
        return Stack.stage;
      case Stack.stage:
        return Stack.stage;
      default:
        return Stack.prod;
    }
  }
}
