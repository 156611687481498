import { container } from 'tsyringe';
import getValueByInsensitiveKey from '../../../utils/getValueByInsensitiveKey';
import getNavigatorLocale from '../utils/getNavigatorLocale';
import { ServiceLocalizationFallbackType } from './../types';
import { AbstractLocaleHandler } from './AbstractLocaleHandler';

export class NavigatorLocaleHandler extends AbstractLocaleHandler {
  private isNative: boolean;

  constructor(languagesFallback: ServiceLocalizationFallbackType) {
    super(languagesFallback);
  }

  public handle(isLanguage: boolean, currentList: string[]): string {
    this.isNative = container.resolve('IsNative');

    if (this.isNative) {
      return super.nextHandle(isLanguage, currentList);
    }

    const navigatorLocale = getNavigatorLocale();

    // For fallback
    const navigatorFallback = getValueByInsensitiveKey(
      this._languagesFallback,
      navigatorLocale?.language
    );

    return super.genericHandle({
      isLanguage: isLanguage,
      locale: navigatorLocale,
      currentList: currentList,
      localeFallback: navigatorFallback
    });
  }
}
