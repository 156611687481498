import * as T from '../logger/types';
import { NewLoggerInterfaceType, LogMethodsType } from './types';

type noopLoggerInterfaceParam = {
  logCallback?: () => void;
};

export const createNoopNewLoggerInterface = ({
  logCallback
}: noopLoggerInterfaceParam): NewLoggerInterfaceType => {
  const newLoggerNoopInterface: NewLoggerInterfaceType = {
    log: logCallback as unknown as LogMethodsType,
    debug: logCallback as unknown as LogMethodsType,
    warn: logCallback as unknown as LogMethodsType,
    error: logCallback as unknown as LogMethodsType,
    createLoggerInstance: function (): Record<T.EnumAllowedLogType, any> {
      console.debug('Function not implemented.');
      return {
        log: undefined,
        debug: undefined,
        warn: undefined,
        error: undefined
      };
    },
    setLocalOptions: function (): void {
      console.debug('Function not implemented.');
    },
    removeLocalOptions: function (): void {
      console.debug('Function not implemented.');
    }
  };
  return newLoggerNoopInterface;
};
