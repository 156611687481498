import { ISessionService } from '../../services/session';
import { ILoginService } from '../../services/session/loginService';
import { IIdleLogoutService } from '../../services/IdleService';
import { ILogoutService } from '../../services/session/logoutService';

type idleLogoutWithSessionParams = {
  sessionService: ISessionService;
  loginService: ILoginService;
  idleLogoutService: IIdleLogoutService;
  logoutService: ILogoutService;
};

export default class UseIdleLogoutTimer {
  private _sessionService: ISessionService;
  private _loginService: ILoginService;
  private _idleLogoutService: IIdleLogoutService;
  private _logoutService: ILogoutService;

  constructor({
    sessionService,
    loginService,
    idleLogoutService,
    logoutService
  }: idleLogoutWithSessionParams) {
    this._sessionService = sessionService;
    this._loginService = loginService;
    this._idleLogoutService = idleLogoutService;
    this._logoutService = logoutService;
  }
  public init(): void {
    const _isLoginPath = this._loginService.isLoginPath();
    const _isLoggedoutPath = this._logoutService.isLoggedoutPath();
    if (
      _isLoginPath ||
      _isLoggedoutPath ||
      !this._sessionService.isLoggedIn()
    ) {
      this._idleLogoutService.stopIdleTimer();
    } else {
      this._idleLogoutService.startIdleTimer();
    }
  }
}
