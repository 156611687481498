import { defaultDebugParams, defaultLogParams } from './defaultLogParams';

export const warnDictionary = [
  {
    id: 'w0', // default warn message
    level: 'warn',
    params: [...defaultLogParams, ...defaultDebugParams]
  },
  {
    id: 'w1',
    title: 'Feature Deprecated',
    level: 'warn',
    params: [
      ...defaultLogParams,
      ...defaultDebugParams,
      'deprecatedFeature',
      'replacementFeature'
    ]
  },
  {
    id: 'w2',
    title: 'Retry Attempt for External API Call',
    level: 'warn',
    params: [
      ...defaultLogParams,
      ...defaultDebugParams,
      'endpoint',
      'retryReason',
      'retryCount'
    ]
  },
  {
    id: 'w3',
    title: 'Non-Critical Configuration Invalid',
    level: 'warn',
    params: [
      ...defaultLogParams,
      ...defaultDebugParams,
      'invalidParameter',
      'fallbackUsed'
    ]
  }
];
