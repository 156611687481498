import {
  SetServiceDependencies,
  UserOnboardingInputType
} from '../../infra/commonInitializer/types';
import { INavigationService } from '../navigationService';

export default abstract class BaseUserOnboardingService {
  private _userOnboardingInputData: UserOnboardingInputType;
  private _navigationService: INavigationService;

  constructor(props: UserOnboardingInputType) {
    this._userOnboardingInputData = props;
  }

  setDependencies({ services }: SetServiceDependencies): void {
    this._navigationService = services.navigationService;
  }

  isUserOnboardingPath(path?: string): boolean {
    const pathToCheck = path || this._navigationService.location.pathname;

    return (
      pathToCheck === this._userOnboardingInputData.path ||
      pathToCheck === this._userOnboardingInputData.userOnboardingPath
    );
  }
}
