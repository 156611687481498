import { PersonClient } from '../../clients/stratus/tntCommonResourceRegistry';
import AccountsClient from '../../clients/stratus/accountmgtsvc/AccountsClient';
import { ISessionService } from '../session';
import IUserOnboardingService from './IUserOnboardingService';
import { DEFAULT_INITIAL_CURRENT_PAGE } from '../../clients/stratus/tntCommonResourceRegistry/constants';
import bindAllMethods from '../../utils/bindAllMethods';
import { UserOnboardingJoinInvitedOrganizationParams } from './types';
import { SetServiceDependencies } from '../../infra/commonInitializer/types';
import BaseUserOnboardingService from './BaseUserOnboardingService';

export type StratusUserOnboardingServiceParams = {
  sessionService: ISessionService;
  stratusAccountMgtSvcClient: AccountsClient;
  stratusTntResourceRegistryPersonClient: PersonClient;
};

export default class StratusUserOnboardingService
  extends BaseUserOnboardingService
  implements IUserOnboardingService
{
  private _DEFAULT_ONBOARDING_ORG_TYPE = 'Personal';

  private _sessionService: ISessionService;
  private _stratusAccountMgtSvcClient: AccountsClient;
  private _stratusTntResourceRegistryPersonClient: PersonClient;

  public setDependencies({
    services,
    clients,
    repositories
  }: SetServiceDependencies): void {
    super.setDependencies({ services, clients, repositories });

    const { sessionService } = services;

    const { stratus } = clients;
    this._sessionService = sessionService;
    this._stratusAccountMgtSvcClient = stratus.accountsMgtSvcClient;
    this._stratusTntResourceRegistryPersonClient = stratus.personClient;
    bindAllMethods(this);
  }

  public async shouldRenderOnboardingScreen(): Promise<boolean> {
    return true;
  }

  public async isUserOnboarded(): Promise<boolean> {
    const personDomains =
      await this._stratusTntResourceRegistryPersonClient.getPersonDomain({
        page: DEFAULT_INITIAL_CURRENT_PAGE
      });

    return !!(personDomains?.content?.length > 0);
  }

  public async onboardUser(): Promise<void> {
    const idToken = this._sessionService.getIdToken();
    await this._stratusAccountMgtSvcClient.createAccount({
      accountId: null,
      accountName: null,
      language: 'en',
      countrySet: ['US'],
      idToken,
      type: this._DEFAULT_ONBOARDING_ORG_TYPE
    });
  }

  public async joinInvitedOrganization(
    options?: UserOnboardingJoinInvitedOrganizationParams
  ): Promise<void> {
    const idToken = this._sessionService.getIdToken();
    await this._stratusAccountMgtSvcClient.createAccount({
      language: 'en',
      countrySet: ['US'],
      idToken,
      accountId: options?.tenantId
    });
  }
}
