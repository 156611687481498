import { ReservedValueStoreKeys } from '../../../services/JWeb/JWebEnums';
import { getJWeb, isNative } from '../../../services/JWeb';
import IApplicationService from '../../../services/applicationService/IApplicationService';
import bindAllMethods from '../../../utils/bindAllMethods';
import { AppInterfaceV1Type } from './types';

export type AppInterfaceV1Param = {
  applicationService: IApplicationService;
};

class AppInterfaceV1 {
  private _applicationService: IApplicationService;

  constructor({ applicationService }: AppInterfaceV1Param) {
    this._applicationService = applicationService;
    bindAllMethods(this);
  }

  public getInterface(): AppInterfaceV1Type {
    return {
      getClientId: () => {
        return this._applicationService.getClientId();
      },
      getAppName: () => {
        return this._applicationService.getAppName();
      },
      getAuthStack: () => {
        return this._applicationService.getAuthStack();
      },
      getPortalStack: () => {
        return this._applicationService.getPortalStack();
      },
      getAppInstanceId: async () => {
        if (isNative()) {
          try {
            const response = await (
              await getJWeb()
            )?.Plugins?.ValueStore?.get?.({
              keys: [ReservedValueStoreKeys.applicationInstanceId]
            });
            const result = (response as any)?.result[0];

            const appInstanceId = result?.Value || result?.value;
            return appInstanceId;
          } catch (err) {
            console.error('Error getting JWeb Value Store', err);
          }
        }
      },
      getAppVersion: async () => {
        if (isNative()) {
          const { appVersion } = await (
            await getJWeb()
          ).Plugins.Device.getInfo();
          return appVersion;
        }
        console.error('getAppVersion is only available in native');
      },
      getPlatform: async () => {
        if (isNative()) {
          const { platform } = await (await getJWeb()).Plugins.Device.getInfo();
          return platform;
        }
        console.error('getPlatform is only available in native');
      },
      getApplicationContext: () => {
        return this._applicationService.getApplicationContext();
      }
    };
  }
}

export default AppInterfaceV1;
