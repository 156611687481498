import { internalLogger } from '../../interface/v1/logger';
import bindAllMethods from '../../utils/bindAllMethods';
import injectOneTrust from './injectOneTrust';

import { SetServiceDependencies } from '../../infra/commonInitializer/types';
import { IAnalyticsService } from '../AnalyticsService';
import { writeDataToValueStore } from '../JWeb';
import { INavigationService } from '../navigationService';

import { OPT_IN_GROUP_VALUE, OPT_OUT_GROUP_VALUE } from '../AnalyticsService';
import { ReservedValueStoreKeys } from '../JWeb/JWebEnums';
import { RouteServiceInputType, RoutesService } from '../RoutesService';
import IApplicationService from '../applicationService/IApplicationService';
import { LOGIN_PATH_LIST } from '../session/constants';
import IConsentService from './IConsentService';
import {
  EventHandlerType,
  getOptanonConsentObjectValues,
  registerOptanonDataLayerEventHandler
} from './optanonUtils';
import { ISessionService } from '../session';
import { IABTestingService } from '../ABTestingService';
import { IRouteStageService } from '../RouteStageService';

export type ConsentServiceInputType = {
  isEmailGathering?: boolean;
  isNative: boolean;
};

/***
 *  The Consent Service is only used in Web envs.
 */
export default class ConsentService implements IConsentService {
  private _analyticsService: IAnalyticsService;
  private _navigationService: INavigationService;
  private _applicationService: IApplicationService;
  private _routesService: RoutesService;
  private _sessionService: ISessionService;
  private _abTestingService: IABTestingService;
  private _routeStageService: IRouteStageService;
  private _isEmailGathering: boolean;
  private _isNative: boolean;
  private lastOptInGroups = undefined;

  constructor(consentServiceParams: ConsentServiceInputType) {
    this._isEmailGathering = consentServiceParams.isEmailGathering;
    this._isNative = consentServiceParams.isNative;
    bindAllMethods(this);
  }

  public setDependencies({ services }: SetServiceDependencies): void {
    this._navigationService = services.navigationService;
    this._analyticsService = services.analyticsService;
    this._applicationService = services.applicationService;
    this._routesService = services.routesService;
    this._sessionService = services.sessionService;
    this._abTestingService = services.abTestingService;
    this._routeStageService = services.routeStageService;

    bindAllMethods(this);
  }

  public async init(): Promise<void> {
    if (this._isNative) return;

    this._registerOnDataLayer();
    this.applyConsentBanner();

    // Initialize lastOptInGroups
    this.lastOptInGroups = (window as any)._O?.getOptInGroups();
  }

  public applyConsentBanner(): void {
    if (this._isNative) return;

    const isAnalyticsEnabled = this._analyticsService?.isEnabled?.();

    //TODO: Move this code to a proper optimizely service.
    const isOptimizelyEnabled = (window as any).Shell?.manifest?.services
      ?.optimizely?.enabled;

    const isABTestingEnabled = this?._abTestingService?.isEnabled();

    // TODO: This should be received from commons adapter on constructor.
    // this._isConsentEnabled = isAnalyticsEnabled || isOptimizelyEnabled;
    if (isAnalyticsEnabled || isOptimizelyEnabled || isABTestingEnabled) {
      this._injectConsentBanner();

      // Creating listener to be trigged when changes the route
      // TODO: this should be a method for subscribe of NavigationService
      this._navigationService.listen(this._injectConsentBanner);
    }

    internalLogger?.debug?.('Consent Banner flow finished.');
  }

  /*** This method is used just to keep up-to-date the consent in value store. */
  public async updateOptInGroupsInValueStore(): Promise<void> {
    const currentOptInGroups = (window as any)._O?.getOptInGroups();
    if (this.lastOptInGroups != currentOptInGroups) {
      await this._writeOptanonConsentInValueStore();
      this.lastOptInGroups = currentOptInGroups;
    }
  }

  private _shouldInjectConsentBanner(
    currentRoute: RouteServiceInputType
  ): boolean {
    return !(currentRoute?.injectConsentBanner === false);
  }

  private _injectConsentBanner(): void {
    const currentPath: string = this._navigationService?.location?.pathname;
    const currentRoute: RouteServiceInputType =
      this._routesService.findRouteByPath(currentPath);

    // check if the current route has a configuration to explicit not inject consent banner.
    const shouldInjectConsentBanner =
      this._shouldInjectConsentBanner(currentRoute);

    if (!shouldInjectConsentBanner) {
      return;
    }

    const isLoginPath = LOGIN_PATH_LIST.some((e) => e === currentPath);

    // not inject the banner if isEmailGathering is disable and url is in some login path.

    if (!this._isEmailGathering && isLoginPath) {
      return;
    }

    //TODO: refactor this nested ifs to a better approach.
    if (this._routeStageService.isStagePath(currentPath)) {
      const stagePath = this._routeStageService.getStageByPath(currentPath);
      if (!stagePath?.public && !this?._sessionService?.isLoggedIn()) {
        return;
      }
    } else if (!currentRoute?.public && !this?._sessionService?.isLoggedIn()) {
      return;
    }

    injectOneTrust(this._applicationService.getPortalStack());
  }

  private async _writeOptanonConsentInValueStore(): Promise<void> {
    if (this._isNative) {
      return;
    }
    const { optanonConsentId, optanonConsent } =
      getOptanonConsentObjectValues();

    await writeDataToValueStore([
      {
        key: ReservedValueStoreKeys.visitorUuid,
        value: optanonConsentId
      },
      {
        key: ReservedValueStoreKeys.webApplicationConsent,
        value: optanonConsent
      },
      {
        key: ReservedValueStoreKeys.applicationInstanceId,
        value: optanonConsentId
      }
    ]);
  }

  private _registerOnDataLayer(): void {
    const writeOptanonConsentInValueStoreOnOptIn: EventHandlerType = {
      event: 'pb.onOptInGroups',
      callback: this._writeOptanonConsentInValueStore,
      groups: OPT_IN_GROUP_VALUE
    };

    const writeOptanonConsentInValueStoreOnOptOut: EventHandlerType = {
      event: 'pb.onOptInGroups',
      callback: this._writeOptanonConsentInValueStore,
      groups: OPT_OUT_GROUP_VALUE
    };

    registerOptanonDataLayerEventHandler(
      writeOptanonConsentInValueStoreOnOptIn
    );
    registerOptanonDataLayerEventHandler(
      writeOptanonConsentInValueStoreOnOptOut
    );
  }
}
