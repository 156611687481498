export type PostLoginRedirectType = {
  postLoginRedirect?: string;
};

export const _getPostLoginRedirect = (
  { postLoginRedirect }: PostLoginRedirectType,
  manifestBasePath?: string
): string => {
  const postLoginRedirectKey = 'postLoginRedirect';
  const urlParams = new URLSearchParams(window.location.search);

  const searchParamPostLoginRedirectUrl = urlParams.get(postLoginRedirectKey);
  const loginDataPostLoginRedirectUrl = postLoginRedirect;
  const defaultPostLoginRedirectUrl = manifestBasePath;
  const postLoginRedirectPath =
    searchParamPostLoginRedirectUrl ||
    loginDataPostLoginRedirectUrl ||
    defaultPostLoginRedirectUrl ||
    '/';
  return postLoginRedirectPath.startsWith('/')
    ? postLoginRedirectPath
    : `/${postLoginRedirectPath}`;
};
