import { SetServiceDependencies } from '../../../infra/commonInitializer/types';
import ILogoutService from './ILogoutService';
import { LogoutOptionsType } from '../ISessionService';
import { CheckPathPropsType, IsLoggedoutPathPropsType } from './types';
import { INavigationService } from '../../../services/navigationService';
import matchPath from '../../../utils/matchPath';
import { LOGGEDOUT_PATHNAME } from '../constants';

export default abstract class BaseLogoutService implements ILogoutService {
  protected _navigationService: INavigationService;

  public abstract setDependencies(options: SetServiceDependencies): void;
  public abstract logout(options?: LogoutOptionsType): Promise<void>;

  private checkPath(props: CheckPathPropsType): boolean {
    let pathToCompare =
      props?.pathToCompare ||
      this?._navigationService?.location?.pathname ||
      '';
    if (!pathToCompare.startsWith('/')) pathToCompare = '/' + pathToCompare;
    return matchPath(props?.shellInternalPath, {
      exact: props?.exact,
      pathToCompare
    });
  }

  public isLoggedoutPath(options?: IsLoggedoutPathPropsType): boolean {
    return this.checkPath({
      pathToCompare: options?.pathToCheck,
      shellInternalPath: LOGGEDOUT_PATHNAME,
      exact: options?.exact
    });
  }
}
