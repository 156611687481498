import { JWebEnums } from '../../../services/JWeb';

// Function to map log levels
export const getNativeLogLevel = (level: string) => {
  switch (level) {
    case 'debug':
      return JWebEnums.LogLevel.Debug;
    case 'error':
      return JWebEnums.LogLevel.Error;
    case 'warn':
      return JWebEnums.LogLevel.Warn;
    case 'log':
      return JWebEnums.LogLevel.Log;
    default:
      return JWebEnums.LogLevel.Log; // Default log level
  }
};
