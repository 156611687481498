import { getServices } from '../../infra/commonInitializer';
import { decodeJWTPayload } from '../../utils/tokenUtils/JWTUtils';
import { AuthContextEnum } from '../authTokenService';
import { ISessionService } from '../session';
import { AuthenticationDataType, UserDataType } from './types';

export default abstract class BaseUserService {
  protected _sessionService: ISessionService;
  protected _userData: UserDataType;

  public async getEmail(): Promise<string> {
    return decodeJWTPayload(this._sessionService?.getIdToken?.())?.email;
  }

  public async getCountry(): Promise<string> {
    return (await this.get()).locale?.split('_')[1]?.toLocaleLowerCase();
  }

  public async refresh(): Promise<void> {
    if (!this._sessionService.isLoggedIn()) return;

    await this.setUserDataByCloudData();

    if (!this._userData?.email) {
      this.setUserDataByIdToken();
    }
  }

  public async get(): Promise<UserDataType> {
    if (!this._userData) {
      await this.refresh();
    }
    return this._userData;
  }

  public getSyncData(): UserDataType {
    if (!this._userData) {
      this.setUserDataByIdToken();
    }
    return this._userData;
  }

  public getAuthenticationData(): AuthenticationDataType {
    const { sessionService, userService, tenantHandlerService } = getServices();
    const tenantData =
      tenantHandlerService.getTenantByContext(AuthContextEnum.tenant) || null;

    return {
      isLoggedIn: sessionService.isLoggedIn(),
      userData: userService.getSyncData() || null,
      tenantData: tenantData
        ? {
            id: tenantData.id,
            name: tenantData.data.name,
            roleCategory: tenantData.data.roleCategory,
            roleName: tenantData.data.roleCategory,
            type: tenantData.data.type,
            tenantGroups: tenantHandlerService.getTenantGroups()
          }
        : null
    };
  }

  protected abstract setUserDataByCloudData(): Promise<void>;

  protected abstract setUserDataByIdToken(): void;
}
