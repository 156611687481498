import AuthenticationProviderEnum from '../../config/authenticationProviderEnum';
import { JWebType } from '../JWeb/types';
import { ApplicationContextEnum } from '../applicationService/enums';
import {
  DebugLoggerType,
  ErrorLoggerType,
  LogLoggerType,
  WarnLoggerType
} from './Dictionaries/types/types';

export type LogMethodsType = Record<string, GenericLogMethod>;

export interface FunctionWithLogMethods {
  (...userParams: any[]): void; //GenericLogMethod
  [key: string]: GenericLogMethod;
}

export interface ILevelLogClass {
  getLogs(): FunctionWithLogMethods;
}

export type GenericLogMethod = {
  (...userParams: any[]): void;
};

export enum EnumAllowedLogType {
  'debug' = 'debug',
  'error' = 'error',
  'log' = 'log',
  'warn' = 'warn'
}

export type OutputLoggerInstanceType = {
  log: FunctionWithLogMethods;
  debug: FunctionWithLogMethods;
  warn: FunctionWithLogMethods;
  error: FunctionWithLogMethods;
};

export type TypedOutputLoggerInstanceType = {
  log: LogLoggerType;
  debug: DebugLoggerType;
  warn: WarnLoggerType;
  error: ErrorLoggerType;
};

export type LoggerAllowedTypes = { [key in EnumAllowedLogType]?: boolean };

export type LoggerInputType = {
  enable?: boolean;
  isNative?: boolean;
  printOnlyValues?: boolean;
  /** Used to print only the values of the log, without the key, or being an object. */
  allowedExpressions?: string[];
  allowedTypes?: LoggerAllowedTypes;
  /**
   * As JWeb Log Plugin doesn't log to the browser console, we duplicate the logs to the browser console.
   * On some app log, you may see your logs duplicated because you are also getting the logs from browser.
   * It is used to debug it on the browser console.
   * If you don't want to have it duplicated, you can disable this by setting this to true.
   */
  disableBrowserConsoleOnNative?: boolean;
  /**
   * Error logs ignore allowedExpressions by default, if you want to force allowedExpressions for error logs, you can set this to true.
   */
  forceAllowedExpressionsForErrors?: boolean;
};

export type SetLocalOptionsType = (options: LoggerInputType) => void;

export type RemoveLocalOptionsType = () => void;

export type CreateLoggerInstanceType = (
  levelLoggerProps?: LevelLogProps
) => TypedOutputLoggerInstanceType;

export interface GenericLogMethodArgs {
  level: string; // Level is used by Native log;
  type: string; // Type is used by Browser log;
  [key: string]: any;
}

export type LevelLogProps = {
  jweb?: JWebType;
  preffixLog?: any;
  shouldCallNativeLog?: boolean;
  shouldCallBrowserLog?: boolean;
  authenticationProvider?: AuthenticationProviderEnum;
  serviceName?: string;
  appName?: string; // PortalName and appName are the same
  envStack?: string; // Environment stack
  applicationContext?: ApplicationContextEnum;
};
