import {
  DebugLogger,
  ErrorLogger,
  InfoLogger,
  WarnLogger
} from './loggerTypes';
import { LevelLogProps, TypedOutputLoggerInstanceType } from './types';

export let newInternalLogger: TypedOutputLoggerInstanceType;

export const updateBareboneLogger = (
  newLogger: TypedOutputLoggerInstanceType
): void => {
  newInternalLogger = newLogger;
};

// Barebone Logger Factory
export const createBareboneLogger = (): void => {
  const minimalLevelLoggerProps: LevelLogProps = {
    jweb: null,
    shouldCallNativeLog: false,
    shouldCallBrowserLog: true,
    serviceName: '@clientos/commons-northbound-api'
  };

  newInternalLogger = {
    debug: new DebugLogger(minimalLevelLoggerProps).getLogs(),
    log: new InfoLogger(minimalLevelLoggerProps).getLogs(),
    warn: new WarnLogger(minimalLevelLoggerProps).getLogs(),
    error: new ErrorLogger(minimalLevelLoggerProps).getLogs()
  };
};
