export type importmapType = {
  imports: Record<string, string>;
};

export function getLocaleUrlFromAssetReference({
  importmap,
  assetReference = '',
  locale = ''
}: {
  importmap: importmapType;
  assetReference?: string;
  locale: string;
}): string {
  if (!assetReference) return '';

  let cleanedAssetReference = assetReference;
  let localePath = 'locale/';

  if (assetReference.includes('react-shell-common-layout')) {
    cleanedAssetReference =
      assetReference.split('/').slice(0, -1).join('/') + '/';
  }

  if (
    assetReference.includes('react-shell-login') ||
    assetReference.includes('react-login-mfe')
  ) {
    localePath = 'assets/locale/';
  }

  const fullUrl = importmap?.imports[cleanedAssetReference];

  if (!fullUrl) return '';

  const baseUrl = fullUrl.split('/').slice(0, -1).join('/') + '/';

  if (!locale) return '';

  const fileName = locale.replace('-', '_') + '.json';
  return baseUrl + localePath + fileName;
}
