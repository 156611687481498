enum ApplicationContextEnum {
  // Runs on a regular web browser with a Portal URL
  WEB_PORTAL = 'WEB_PORTAL',
  // Run in a native app with a Portal URL
  NATIVE_PORTAL = 'NATIVE_PORTAL',
  // Run in a native app webview with local assets
  NATIVE_APP = 'NATIVE_APP'
}

export { ApplicationContextEnum };
