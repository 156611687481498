import { logDictionary } from '../Dictionaries/logDictionary';
import { LogLoggerType } from '../Dictionaries/types/types';
import * as T from '../types';
import { ILevelLogClass, LevelLogProps } from '../types';
import { genericLogMethod } from '../utils/genericLogMethod';

class InfoLogger implements ILevelLogClass {
  private _infoLogs: T.FunctionWithLogMethods = {} as T.FunctionWithLogMethods;

  constructor(loggerProps: LevelLogProps) {
    // default log
    const _defaultLog = (...value) => this._infoLogs['i0'](...value);

    // Kind of a Log Factory
    logDictionary.forEach((logMethods) => {
      const _method = genericLogMethod({
        ...logMethods,
        loggerProps,
        preffixLog: loggerProps.preffixLog,
        serviceName: loggerProps.serviceName,
        type: 'log',
        rootPrintOnlyValues: logMethods.id === 'i0'
      });
      _defaultLog[logMethods.id] = _method;
    });
    this._infoLogs = _defaultLog as unknown as T.FunctionWithLogMethods;
  }

  getLogs(): LogLoggerType {
    return this._infoLogs as LogLoggerType;
  }
}
export default InfoLogger;
