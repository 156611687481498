import { LoggerInputType } from '../../../services/loggerService/types';
import NewLoggerInterface from './loggerInterface';
import { createNoopNewLoggerInterface } from './noopLoggerInterface';
import { NewLoggerInterfaceType } from './types';

let loggerInterface: NewLoggerInterface;

export default async function initializeLoggerInterface(
  loggerDataInput: LoggerInputType
): Promise<NewLoggerInterfaceType> {
  if (!loggerDataInput?.enable) {
    return createNoopNewLoggerInterface({
      logCallback: () => console.warn('Logger is Disabled')
    });
  }

  if (loggerInterface) return loggerInterface.getInterface();
  loggerInterface = new NewLoggerInterface();

  return loggerInterface.getInterface();
}
