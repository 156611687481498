import { EnumAllowedLogType } from '../types';
import { flatMessage } from './genericLogMethod';

export const isValidExpressions = (
  type,
  forceAllowedExpressionsForErrors,
  allowedExpressions,
  log
): boolean => {
  // The vast majority of logs are not errors and doesn't contain allowedExpressions, so we can skip the validation checking here
  if (type !== EnumAllowedLogType.error && !allowedExpressions?.length) {
    return true;
  }

  const shouldErrorIgnoreAllowedExpressions =
    type === EnumAllowedLogType.error && !forceAllowedExpressionsForErrors;

  const shouldCheckAllowedExpressions =
    !shouldErrorIgnoreAllowedExpressions && allowedExpressions?.length;

  if (shouldCheckAllowedExpressions) {
    const args = flatMessage(log);
    return allowedExpressions.some((expression) =>
      args.some((arg) => {
        return typeof arg === 'string' && new RegExp(expression).test(arg);
      })
    );
  }
  return true;
};
