import { decodeJWTPayload } from '../../utils/tokenUtils/JWTUtils';
import { ISessionService } from '../session';
import IUserService from './IUserService';
import { IAuthProviderService } from '../authProviderService';
import BaseUserService from './BaseUserService';
import { SetServiceDependencies } from '../../infra/commonInitializer/types';
import { UserDataType } from './types';
import bindAllMethods from '../../utils/bindAllMethods';

export type OnecloudUserServiceParams = {
  sessionService: ISessionService;
  authProviderService: IAuthProviderService;
};

export default class OnecloudUserService
  extends BaseUserService
  implements IUserService
{
  protected _sessionService: ISessionService;
  protected _userData: UserDataType;
  public setDependencies({ services }: SetServiceDependencies): void {
    const { sessionService } = services;
    this._sessionService = sessionService;

    bindAllMethods(this);
  }

  // Not implemented
  protected async setUserDataByCloudData(): Promise<void> {
    return;
  }

  protected setUserDataByIdToken(): void {
    const idToken = this._sessionService?.getIdToken?.();

    if (!idToken) {
      return null;
    }
    const idTokenData = decodeJWTPayload(idToken);

    this._userData = {
      idpType: idTokenData.idp_type,
      email: {
        value: idTokenData.email,
        verified: idTokenData.email_verified
      },
      phoneNumber: {
        value: idTokenData.phone_number,
        verified: idTokenData.phone_number_verified
      },
      familyName: idTokenData.family_name,
      givenName: idTokenData.given_name,
      userName: idTokenData.preferred_username,
      locale: idTokenData.locale
    } as UserDataType;
  }
}
