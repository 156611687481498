import IApplicationService from '../../services/applicationService/IApplicationService';
import { IAuthTokenService } from '../../services/authTokenService';
import { ITenantHandlerService } from '../../services/tenantHandler';
import TenantObserver, {
  TenantEvents
} from '../../services/tenantHandler/TenantObserver';
import { internalLogger } from '../../interface/v1/logger';
import { getJWeb, JWebErrorHandler } from '../../services/JWeb';
import { TokenType } from '../../services/JWeb/JWebEnums';
import { AccessToken } from '../../services/JWeb/types';

export type SetFirstTenantForNativeType = {
  authTokenService: IAuthTokenService;
  tenantHandlerService: ITenantHandlerService;
  applicationService: IApplicationService;
};

export default class SetFirstTenantForNative {
  private _authTokenService: IAuthTokenService;
  private _tenantHandlerService: ITenantHandlerService;

  constructor({
    authTokenService,
    tenantHandlerService
  }: SetFirstTenantForNativeType) {
    this._authTokenService = authTokenService;
    this._tenantHandlerService = tenantHandlerService;
  }

  public async init(): Promise<void> {
    this.setInitialTenants();
    TenantObserver.subscribe(
      TenantEvents.SET_TENANT_HANDLER_KEY,
      this.setFirstTenant
    );
  }

  public setInitialTenants = async (): Promise<void> => {
    try {
      const JWebAuth = await getJWeb()?.then?.((res) => res?.Plugins?.Auth);
      const jwebToken = JWebErrorHandler<AccessToken>(
        await JWebAuth.getToken({
          tokenProviderOptions: { tokenType: TokenType.user }
        })
      );
      const jwebTokenTenantId =
        this._authTokenService?.getTenantIdFromGivenToken(jwebToken.tokenValue);
      const shellTokenTenantId = this._authTokenService?.getTenantIdFromToken();
      if (jwebTokenTenantId !== shellTokenTenantId) {
        internalLogger?.debug?.(
          'Setting in ClientOS the following tenant:',
          jwebTokenTenantId
        );
        return this._tenantHandlerService?.setInitialTenants([
          {
            id: jwebTokenTenantId,
            level: this._tenantHandlerService?.START_TENANT_LEVEL
          }
        ]);
      }
    } catch (error) {
      console.error('Error setting initial tenant:', error);
    }
  };

  public setFirstTenant = async (): Promise<void> => {
    const tokenTenantId = this._authTokenService?.getTenantIdFromToken();
    const tenantHandlerTenantId = this._tenantHandlerService?.getTenantId();
    if (tokenTenantId && !tenantHandlerTenantId) {
      internalLogger?.debug?.(
        'Setting in ClientOS the following tenant:',
        tokenTenantId
      );
      await this._tenantHandlerService?.setTenant(
        tokenTenantId,
        this._tenantHandlerService?.START_TENANT_LEVEL,
        { reload: false }
      );
      return;
    }
  };
}
