import { decodeJWTPayload } from '../../utils/tokenUtils/JWTUtils';
import UserMgtClient from '../../clients/stratus/usersMgt/UserMgtClient';
import { SetServiceDependencies } from '../../infra/commonInitializer/types';
import BaseUserService from './BaseUserService';
import { UserDataType } from './types';
import bindAllMethods from '../../utils/bindAllMethods';
import { ISessionService } from '../session';
import IUserService from './IUserService';

export type StratusUserServiceParams = {
  sessionService: ISessionService;
  userMgtClient: UserMgtClient;
};

export default class StratusUserService
  extends BaseUserService
  implements IUserService
{
  protected _sessionService: ISessionService;
  protected _userData: UserDataType;
  private _userMgtClient: UserMgtClient;

  constructor() {
    super();
    bindAllMethods(this);
  }

  public setDependencies({ services, clients }: SetServiceDependencies): void {
    const { sessionService } = services;

    const { stratus } = clients;
    this._sessionService = sessionService;
    this._userMgtClient = stratus.userMgtClient;
    bindAllMethods(this);
  }

  protected async setUserDataByCloudData(): Promise<void> {
    const userData = await this._userMgtClient.getUsersMe();
    this._userData = {
      idpType: userData.idpType,
      email: userData.email,
      phoneNumber: userData.phoneNumber,
      familyName: userData.familyName,
      givenName: userData.givenName,
      userName: userData.userName,
      locale: userData.locale
    };
  }

  protected setUserDataByIdToken(): void {
    const idToken = this._sessionService?.getIdToken?.();
    if (!idToken) return null;
    const idTokenData = decodeJWTPayload(idToken);

    this._userData = {
      idpType: idTokenData.idp_type,
      email: {
        value: idTokenData.email,
        verified:
          idTokenData?.email_verified?.toString() === 'true' ? true : false
      },
      phoneNumber: {
        value: idTokenData.phone_number,
        verified:
          idTokenData?.phone_number_verified?.toString() === 'true'
            ? true
            : false
      },
      familyName: idTokenData.family_name,
      givenName: idTokenData.given_name,
      userName: idTokenData.preferred_username,
      locale: idTokenData.locale
    };
  }
}
