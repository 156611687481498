import bindAllMethods from '../../utils/bindAllMethods';
import {
  AuthContextEnum,
  IAuthTokenService
} from '../../services/authTokenService';
import { JWEB_MAX_THRESHOLD_TIME, JWEB_THRESHOLD_TIME } from './constants';
import { getDateDifferenceInSeconds } from './utils/getDateDifferenceInSeconds';

type CriticalScopeHandlerOptions = {
  isNative: boolean;
};
class CriticalScopeHandler {
  private _authTokenService: IAuthTokenService;
  private isNative: boolean;

  constructor(
    options: CriticalScopeHandlerOptions,
    authTokenService: IAuthTokenService
  ) {
    this._authTokenService = authTokenService;
    this.isNative = options.isNative;
    bindAllMethods(this);
  }

  private _getTokenExpirationInfo() {
    // TODO: refactor this when understand how to get the token from the correct context
    let accessToken = this._authTokenService.getToken(AuthContextEnum.tenant);
    if (!accessToken?.token) {
      accessToken = this._authTokenService.getToken(AuthContextEnum.tenantless);
    }

    if (!accessToken) return;

    const decodedAccessToken = this._authTokenService.decodeJWT(
      accessToken.token
    );
    return { iat: decodedAccessToken.iat, ca_exp: decodedAccessToken.ca_exp };
  }

  public isCriticalScopesValid(): boolean {
    const tokenData = this._getTokenExpirationInfo();

    // TODO : check what need to return if doesn't have tokenData
    if (!tokenData) return true;

    const criticalScopesExpirationDate = new Date(tokenData.ca_exp * 1000);
    return criticalScopesExpirationDate.getTime() > Date.now();
  }

  public getLoginCooldown = (): number => {
    if (this.isNative) {
      const tokenData = this._getTokenExpirationInfo();
      if (!tokenData) return 0;
      const tokenCreationDate = new Date(tokenData.iat * 1000);
      return Math.min(
        JWEB_MAX_THRESHOLD_TIME,
        Math.max(
          0,
          JWEB_THRESHOLD_TIME -
            getDateDifferenceInSeconds(Date.now(), tokenCreationDate.getTime())
        )
      );
    }

    return 0;
  };

  /** EnsureCriticalScopes analyses if the user NEEDS login again to handle with critical scopes, i.e., if its token was created in less than 15 minutes, being in the window to handle critical scopes.
   * If returns true, the user must login again.
   * if returns false, the user doesn't need to login again.
   * If the user is in the cooldown time, it will return undefined.
   */
  public ensureCriticalScopes = (waitCallback = () => {}): boolean => {
    // Is in Critical scope state.
    if (this.isCriticalScopesValid()) {
      return false;
    }

    if (!this.isNative) {
      // The token is always valid in Web Version
      return true;
    }

    // Native Version
    const threshold = this.getLoginCooldown();

    if (threshold <= 0) {
      return true;
    } else {
      // This is the case where the user is in the cooldown time, and can't login again.
      waitCallback();
      return undefined;
    }
  };
}

export { CriticalScopeHandlerOptions, CriticalScopeHandler };
