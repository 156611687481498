import { defaultDebugParams, defaultLogParams } from './defaultLogParams';

export const errorDictionary = [
  {
    id: 'e0', // default error  message
    level: 'error',
    params: [...defaultLogParams, 'appName']
  },
  {
    id: 'e1',
    title: 'Partial Task Failure',
    level: 'error',
    params: [...defaultLogParams, ...defaultDebugParams, 'fallbackAction']
  },
  {
    id: 'e2',
    title: 'External API Call Failure',
    level: 'error',
    params: [
      ...defaultLogParams,
      ...defaultDebugParams,
      'endpoint',
      'responseCode',
      'errorMessage',
      'retryAttempts'
    ]
  },
  {
    id: 'e3',
    title: 'Unhandled Exception',
    level: 'error',
    params: [...defaultLogParams, ...defaultDebugParams, 'fallbackAction']
  },
  {
    id: 'e4',
    title: 'Authentication Error',
    level: 'error',
    params: [
      ...defaultLogParams,
      ...defaultDebugParams,
      'userId',
      'failureReason'
    ]
  },
  {
    id: 'e5',
    title: 'Service Unavailable',
    level: 'error',
    params: [
      ...defaultLogParams,
      ...defaultDebugParams,
      'externalServiceName',
      'endpoint',
      'errorMessage'
    ]
  }
];
