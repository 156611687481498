import { InterfacesInitializerStatePropsType } from '../../../interface/types';

export type LogType = any;

export type LogFuncType = (...args: LogType[]) => void;

export type CreateLoggerInstanceOptionsType = {
  preffixLog?: LogType;
};

export type CreateLoggerOptionsType = CreateLoggerInstanceOptionsType & {
  type: EnumAllowedLogType;
};

export type CreateLoggerType = (
  options: CreateLoggerOptionsType & LoggerInputType
) => LogFuncType;

export type CreateLoggerInstanceType = (
  options?: CreateLoggerInstanceOptionsType
) => Record<EnumAllowedLogType, LogFuncType>;

export type SetLocalOptionsType = (options: LoggerInputType) => void;

export type RemoveLocalOptionsType = () => void;

export type LoggerType = Record<EnumAllowedLogType, LogFuncType> & {
  createLoggerInstance: CreateLoggerInstanceType;
  setLocalOptions: SetLocalOptionsType;
  removeLocalOptions: RemoveLocalOptionsType;
};

export type ServiceRoutingInitializerStatePropsType =
  InterfacesInitializerStatePropsType;

export enum EnumAllowedLogType {
  'debug' = 'debug',
  'error' = 'error',
  'log' = 'log',
  'warn' = 'warn'
}

export type LoggerAllowedTypes = { [key in EnumAllowedLogType]?: boolean };

export type LoggerInputType = {
  enable?: boolean;
  isNative?: boolean;
  printOnlyValues?: boolean;
  /** Used to print only the values of the log, without the key, or being an object. */
  allowedExpressions?: string[];
  allowedTypes?: LoggerAllowedTypes;
  /**
   * As JWeb Log Plugin doesn't log to the browser console, we duplicate the logs to the browser console.
   * On some app log, you may see your logs duplicated because you are also getting the logs from browser.
   * It is used to debug it on the browser console.
   * If you don't want to have it duplicated, you can disable this by setting this to true.
   */
  disableBrowserConsoleOnNative?: boolean;
  /**
   * Error logs ignore allowedExpressions by default, if you want to force allowedExpressions for error logs, you can set this to true.
   */
  forceAllowedExpressionsForErrors?: boolean;
};
