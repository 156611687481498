import { ILevelLogClass, LevelLogProps } from '../types';
import { genericLogMethod } from '../utils/genericLogMethod';
import { warnDictionary } from '../Dictionaries/WarnDictionary';
import * as T from '../types';
import { WarnLoggerType } from '../Dictionaries/types/types';

class WarnLogger implements ILevelLogClass {
  private _warnLogs: T.FunctionWithLogMethods = {} as T.FunctionWithLogMethods;

  constructor(loggerProps: LevelLogProps) {
    // default log
    const _defaultLog = (...value) => this._warnLogs['w0'](...value);

    warnDictionary.forEach((logMethods) => {
      const _method = genericLogMethod({
        ...logMethods,
        loggerProps,
        type: 'warn',
        rootPrintOnlyValues: logMethods.id === 'w0',
        // Setting the values from Initialization
        preffixLog: loggerProps.preffixLog,
        serviceName: loggerProps.serviceName,
        // Setting the default values from the ApplicationService
        appName: loggerProps.appName,
        applicationContext: loggerProps.applicationContext,
        envStack: loggerProps.envStack,
        authenticationProvider: loggerProps.authenticationProvider
      });
      _defaultLog[logMethods.id] = _method;
    });
    this._warnLogs = _defaultLog as unknown as T.FunctionWithLogMethods;
  }

  getLogs(): WarnLoggerType {
    return this._warnLogs as WarnLoggerType;
  }
}

export default WarnLogger;
