import { LOGIN_PATHNAME } from '../constants';
import {
  RedirectToLoginParams,
  RedirectToLoginParamsObjectType
} from '../loginService/ILoginService';
import { getURLSearchParams } from './getURLSearchParams';

/**
 * Looking into the code, we composite the login path by:
 * 1. Getting the parameters from the window.location.search (using URLSearchParams);
 * 2. Copying the parameters from the params object to the URLSearchParams object;
 */

export const _getLoginPath = (params?: RedirectToLoginParams): string => {
  const urlSearchParams = getURLSearchParams(window.location.search);

  copySearchParams(params, urlSearchParams);

  // Create a postLoginRedirect unless it exists

  if (!urlSearchParams.get('postLoginRedirect')) {
    // This case already contains locale in  window.location.pathname.
    const defaultPostLoginRedirect = window.location.pathname.endsWith(
      LOGIN_PATHNAME
    )
      ? '/'
      : window.location.pathname + window.location.search;

    urlSearchParams.set('postLoginRedirect', defaultPostLoginRedirect);
  }

  let forceLoginValue;
  if (urlSearchParams.get('prompt')) {
    forceLoginValue = urlSearchParams.get('prompt');

    urlSearchParams.delete('prompt');
  }

  urlSearchParams.forEach((value, key) => {
    if (!value) {
      urlSearchParams.delete(key);
    }
  });

  const searchParams = urlSearchParams?.toString?.() || '';

  let result = LOGIN_PATHNAME;
  let hasSearchParamSetted = false;

  if (searchParams?.length > 0) {
    result += `?${searchParams}`;
    hasSearchParamSetted = true;
  }

  if (forceLoginValue) {
    if (hasSearchParamSetted) {
      result += `&prompt=${forceLoginValue}`;
    } else {
      result += `?prompt=${forceLoginValue}`;
    }
  }

  return result;
};

export function copySearchParams(
  params: RedirectToLoginParams,
  urlSearchParams: URLSearchParams
): void {
  getURLSearchParams(params).forEach((value, key) => {
    let thisKey = key;
    let thisValue = value;

    if (
      key === 'loginType' &&
      (value as RedirectToLoginParamsObjectType['loginType']) ===
        'createAccount'
    ) {
      thisKey = 'target';
      thisValue = 'create';
    }

    if (key === 'forceLogin' && value) {
      thisKey = 'prompt';
      thisValue = 'login+consent';
    }

    if (value && key) {
      urlSearchParams.set(thisKey, thisValue);
    }
  });
}
