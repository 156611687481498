import { ISessionService } from '../../services/session';
import { clearRepositories } from '../../infra/clearRepositories';
import { getRepositories } from '../../infra/commonInitializer';
import { internalLogger } from '../../interface/v1/logger';
import BaseLoginService from '../../services/session/loginService/BaseLoginService';

export type CleanUpEnvironmentType = {
  sessionService: ISessionService;
};

export default class CleanUpEnvironment {
  private _sessionService: ISessionService;

  constructor({ sessionService }: CleanUpEnvironmentType) {
    this._sessionService = sessionService;
  }

  public init = (): void => {
    this._clearRepositories();

    this._clearLastTenantId();
  };

  private _clearLastTenantId() {
    const isLoginPath = BaseLoginService.isInLoginPath();
    const isLoggedOut = this._isLoggedOut();
    const { lastTenantIdsRepository } = getRepositories();

    /** Currently, we are only cleaning the last tenant id from the local storage
     * if the user is not logged in or is the login path.
     */
    if (isLoginPath || isLoggedOut) {
      /* istanbul ignore next */
      internalLogger?.debug?.('Cleaning up lastTenantIdsRepository. ');
      lastTenantIdsRepository.clear();
    }
  }

  private _clearRepositories(): void {
    const isLoggedOut = this._isLoggedOut();
    const isLoggedinPath = BaseLoginService.isInLoggedinPath();

    const clearRepositoriesRules = [isLoggedOut, isLoggedinPath];

    if (clearRepositoriesRules.some((r) => r)) {
      /* istanbul ignore next */
      internalLogger?.debug?.(
        'Cleaning Repositories because the user is logged out or is in the /loggedin path. Skipping Repositories: LastTenantIdsRepository.'
      );
      clearRepositories(['lastTenantIdsRepository']);
    }
  }

  private _isLoggedOut(): boolean {
    const isLoggedin = this._sessionService.isLoggedIn();
    return !isLoggedin;
  }
}
