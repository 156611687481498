import { IABTestingService } from '../../../services/ABTestingService';
import { getServices } from '../../../infra/commonInitializer';
import bindAllMethods from '../../../utils/bindAllMethods';
import { ABTestingInterfaceType } from './types';

class ABTestingInterface {
  private _abTestingService: IABTestingService;

  constructor() {
    const services = getServices();
    this._abTestingService = services.abTestingService;

    bindAllMethods(this);
  }

  public getInterface(): ABTestingInterfaceType {
    return {
      isEnabled: () => this._abTestingService.isEnabled()
    };
  }
}

export default ABTestingInterface;
