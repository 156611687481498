import bindAllMethods from '../../../utils/bindAllMethods';
import { ILogoutClient } from '../../../clients/shell/logout';
import ILogoutService from './ILogoutService';
import { SetServiceDependencies } from '../../../infra/commonInitializer/types';
import { INavigationService } from '../../../services/navigationService';
import BaseLogoutService from './BaseLogoutService';

export type ShellLogoutServiceParameters = {
  logoutClient: ILogoutClient;
};

// This service is current being user in Coptor and in AST.
export default class ShellLogoutService
  extends BaseLogoutService
  implements ILogoutService
{
  private _logoutClient: ILogoutClient;

  public setDependencies({ clients, services }: SetServiceDependencies): void {
    const { shell } = clients;
    const { navigationService } = services;
    this._logoutClient = shell.logoutClient;
    this._navigationService = navigationService;

    bindAllMethods(this);
  }

  public async logout(): Promise<void> {
    const redirectTo = this._logoutClient.configureLogoutUrl(
      location.origin,
      'shell'
    );

    return new Promise(() => {
      this._navigationService.redirect(redirectTo);
    });
  }
}
