import bindAllMethods from '../../../utils/bindAllMethods';
import {
  GetTenantListOptionsType,
  PaginatedTenantVisualizationType,
  TenantVisualizationType
} from '../../../services/tenantHandler/strategy/types';
import {
  AuthContextEnum,
  IAuthTokenService
} from '../../../services/authTokenService';
import authContextEnumToTenantLevel from '../../../services/authTokenService/utils/authContextEnumToTenantLevel';
import { ISupportSessionService } from '../../../services/supportSession';
import { getServices } from '../../../infra/commonInitializer';
import {
  TenantChangeEventV1,
  TenantDetailsV1,
  TenantHandlerInterfaceType
} from './types';
import tenantLevelToAuthContextEnum from '../../../services/authTokenService/utils/tenantLevelToAuthContextEnum';
import ITenantHandlerService from '../../../services/tenantHandler/ITenantHandlerService';
import { PortalOnboardingType } from '../../../services/tenantHandler/strategy/external-types';

export default class TenantHandlerInterfaceV1
  implements TenantHandlerInterfaceType
{
  private _tenantHandlerService: ITenantHandlerService;
  private _supportSessionService: ISupportSessionService;
  private _authTokenService: IAuthTokenService;

  constructor() {
    const services = getServices();
    this._authTokenService = services?.authTokenService;
    this._supportSessionService = services?.supportSessionService;
    this._tenantHandlerService = services?.tenantHandlerService;
    bindAllMethods(this);
  }

  public isEnabled(): boolean {
    return this._tenantHandlerService.isEnabled();
  }

  public getCurrentContext(): AuthContextEnum {
    return this._tenantHandlerService.getCurrentContext();
  }
  public getTenantData(params?: {
    authContext?: AuthContextEnum;
  }): TenantVisualizationType {
    const authContext =
      params?.authContext || this._authTokenService.getCurrentContext();

    const tenantData =
      this._tenantHandlerService.getTenantByContext(authContext);
    return {
      id: tenantData?.id,
      name: tenantData?.data?.name,
      type: tenantData?.data?.type,
      roleCategory: tenantData?.data?.roleCategory
    };
  }

  public getTenantId(authContext?: AuthContextEnum): string {
    if (this._supportSessionService.isSupportSession()) {
      return this._authTokenService.getTenantIdFromToken(authContext);
    }
    const userAuthContext =
      authContext || this._authTokenService.getCurrentContext();
    const tenantLevel = authContextEnumToTenantLevel(userAuthContext);
    return this._tenantHandlerService.getTenantId(tenantLevel);
  }

  /** By default, the reload will be made. */
  public setTenant({
    tenantId,
    authContext,
    preventReload,
    useCustomBehavior
  }: {
    tenantId: string;
    authContext: AuthContextEnum;
    preventReload?: boolean;
    useCustomBehavior?: boolean;
  }): Promise<boolean> {
    const tenantLevel = authContextEnumToTenantLevel(authContext);
    const options = { reload: !preventReload, useCustomBehavior };
    return this._tenantHandlerService.setTenant(tenantId, tenantLevel, options);
  }

  public async getTenantById({
    tenantId,
    authContext
  }: {
    tenantId: string;
    authContext: AuthContextEnum;
  }): Promise<TenantVisualizationType> {
    const tenantLevel = authContextEnumToTenantLevel(authContext);
    return this._tenantHandlerService
      .getTenantStrategy(tenantLevel)
      .getTenantById(tenantId);
  }

  public async getTenantList({
    authContext,
    refresh
  }: {
    authContext: AuthContextEnum;
    refresh?: boolean;
  }): Promise<TenantVisualizationType[]> {
    const tenantLevel = authContextEnumToTenantLevel(authContext);
    return this._tenantHandlerService
      .getTenantStrategy(tenantLevel)
      .getTenantList(refresh);
  }

  public async getPaginatedTenantList({
    authContext,
    ...options
  }: {
    authContext: AuthContextEnum;
  } & GetTenantListOptionsType): Promise<PaginatedTenantVisualizationType> {
    const tenantLevel = authContextEnumToTenantLevel(authContext);
    return this._tenantHandlerService
      .getTenantStrategy(tenantLevel)
      .getPaginatedTenantList(options);
  }

  public getNextUnproccessedTenant(
    forceAuthContext?: AuthContextEnum
  ): TenantDetailsV1 {
    const nextUnproccessedTenantFromService =
      this._tenantHandlerService.getNextUnproccessedTenant(
        forceAuthContext
      ) as TenantDetailsV1;

    if (nextUnproccessedTenantFromService?.level) {
      nextUnproccessedTenantFromService['authContext'] =
        tenantLevelToAuthContextEnum(nextUnproccessedTenantFromService.level);
    }
    return nextUnproccessedTenantFromService;
  }

  public onTenantChange(
    cb: (options: TenantChangeEventV1) => void
  ): () => void {
    return this._tenantHandlerService.onTenantChange(cb);
  }

  public getPortalOnboardingList(): PortalOnboardingType[] {
    return this._tenantHandlerService.getPortalOnboardingList();
  }
}
