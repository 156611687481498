import { defaultLogParams } from './defaultLogParams';

export const logDictionary = [
  {
    id: 'i0', // default log  message
    level: 'log',
    params: defaultLogParams
  },
  {
    id: 'i1',
    title: 'Successfully initialized',
    level: 'log',
    params: [...defaultLogParams, 'method']
  },
  {
    id: 'i2',
    title: 'Task Execution Status',
    level: 'log',
    params: [...defaultLogParams, 'taskName', 'taskId', 'status']
  },
  {
    id: 'i3',
    title: 'Subscribed to New Event Channel',
    level: 'log',
    params: [...defaultLogParams, 'channelName']
  },
  {
    id: 'i4',
    title: 'Event Published Successfully',
    level: 'log',
    params: [...defaultLogParams, 'eventType', 'channelName']
  },
  {
    id: 'i5',
    title: 'Listener Initialized',
    level: 'log',
    params: [...defaultLogParams, 'listenerName']
  },
  {
    id: 'i6',
    title: 'Event Received Successfully',
    level: 'log',
    params: [...defaultLogParams, 'eventType', 'source']
  },
  {
    id: 'i7',
    title: 'User Action Tracked',
    level: 'log',
    params: [...defaultLogParams, 'userId', 'action']
  }
];
