import { internalLogger } from '../../interface/v1/logger';
import IIdleLogoutService from './IIdleLogoutService';
import * as T from './types';
import { getServices } from '../../infra/commonInitializer';
export default class IdleLogoutService implements IIdleLogoutService {
  private isActive: boolean;
  private time: number;
  private listeners?: Record<string, boolean>;
  private idleLogoutSettings: T.IdleLogoutInputType;
  private timeout?: NodeJS.Timeout;

  constructor(options: T.IdleLogoutInputType) {
    this.isActive = false;
    this.time = 0;
    this.restart = this.restart.bind(this);
    this.idleLogoutSettings = options;
  }

  private handleEvents(method: 'addEventListener' | 'removeEventListener') {
    if (typeof this.listeners === 'object') {
      for (const key in this.listeners) {
        if (this.listeners[key] === true) {
          document[method](key, this.restart);
        }
      }
    }
  }

  private start() {
    this.handleEvents('addEventListener');
    this.timeout = setTimeout(this.idleTimeoutFunction.bind(this), this.time);
  }

  private stop() {
    this.handleEvents('removeEventListener');
    clearTimeout(this.timeout);
  }

  private idleTimeoutFunction() {
    this.handleEvents('removeEventListener');
    const { sessionService } = getServices() || {};

    if (sessionService?.isLoggedIn?.()) {
      sessionService?.logout?.().catch((err) => internalLogger.error(err));
    }
  }

  private restart() {
    this.stop();
    this.start();
  }

  public stopIdleTimer() {
    if (this.isActive) {
      this.stop();
      this.isActive = false;
    }
  }

  public startIdleTimer() {
    const { listeners: manifestListeners, time: manifestTime } =
      this.idleLogoutSettings || {};

    if (!this.isActive && manifestTime > 0) {
      this.isActive = true;
      this.time = manifestTime;
      this.listeners = manifestListeners;

      this.start();
    }
  }
}
