import 'reflect-metadata';
import BeforeInitBehaviors from '../../behaviors/beforeInitBehaviors';
import ClientsInitializer from './ClientsInitializer';
import { ClientsType } from './ClientsType';
import ManifestToCommonsAdapter from './ManifestToCommonsAdapter';
import RepositoryInitializer from './RepositoriesInitializer';
import { RepositoriesType } from './RepositoriesType';
import ServicesInitializer from './ServicesInitializer';
import { ServicesType } from './ServicesType';
import { ManifestType } from './types';

let services: ServicesType;
let clients: ClientsType;
let repositories: RepositoriesType;

export async function initializeCommons(manifest: ManifestType): Promise<void> {
  // Currently it's using the manifest here, but it's already using an adapter
  const commonsInitializeProps = await ManifestToCommonsAdapter.convert(
    manifest
  );

  // Instantiation
  repositories = await RepositoryInitializer.instantiate();

  ServicesInitializer.registerOverridableSingletons();
  services = await ServicesInitializer.instantiate(commonsInitializeProps);

  const {
    graphQLService,
    applicationService,
    authProviderService,
    sessionService,
    webServiceRouting,
    navigationService,
    localizationService,
    loggerService,
    routesService,
    serviceWorkerService,
    tenantHandlerService,
    featureFlagService
  } = services;

  clients = ClientsInitializer.instantiate({
    applicationService,
    authProviderService
  });

  // Resolve/set dependencies
  await ServicesInitializer.resolveDependencies({
    services,
    clients,
    repositories
  });

  // Initialize priority services
  // >> keep this at first
  await localizationService.init();
  navigationService.init();
  await graphQLService.init();

  await sessionService.init();

  // Run Before initialization behaviors
  await BeforeInitBehaviors.init();

  // Initialize other services
  webServiceRouting.initialize();
  await tenantHandlerService.init(sessionService);
  serviceWorkerService.init();
  featureFlagService.init();
  await loggerService.init();

  const navigation = navigationService;
  const currentPath = navigation.location.pathname;
  const { tenantHandlerOverride } =
    (await routesService?.findRouteWithPriority?.(currentPath, false)) || {};
  await tenantHandlerService?.setTenantHandlerKey(tenantHandlerOverride);
}

export function getServices(): ServicesType {
  if (!services) throw new Error('Initialize the services first.');
  return services;
}

export function getClients(): ClientsType {
  if (!clients) throw new Error('Initialize the services first.');
  return clients;
}

export function getRepositories(): RepositoriesType {
  if (!repositories) throw new Error('Initialize the services first.');
  return repositories;
}
