import { ILevelLogClass, LevelLogProps } from '../types';
import { genericLogMethod } from '../utils/genericLogMethod';
import { debugDictionary } from '../Dictionaries/DebugDictionary';
import * as T from '../types';
import { DebugLoggerType } from '../Dictionaries/types/types';

class DebugLogger implements ILevelLogClass {
  private _debugLogs: T.FunctionWithLogMethods = {} as T.FunctionWithLogMethods;

  constructor(loggerProps: LevelLogProps) {
    // default log
    const _defaultLog = (...value) => this._debugLogs['d0'](...value);

    debugDictionary.forEach((logMethods) => {
      const _method = genericLogMethod({
        ...logMethods,
        loggerProps,
        type: 'debug',
        rootPrintOnlyValues: logMethods.id === 'd0',
        // Setting the values from Initialization
        preffixLog: loggerProps.preffixLog,
        serviceName: loggerProps.serviceName,

        // Setting the default values from the ApplicationService
        appName: loggerProps.appName,
        applicationContext: loggerProps.applicationContext,
        envStack: loggerProps.envStack,
        authenticationProvider: loggerProps.authenticationProvider
      });
      _defaultLog[logMethods.id] = _method;
    });
    this._debugLogs = _defaultLog as unknown as T.FunctionWithLogMethods;
  }

  getLogs(): DebugLoggerType {
    return this._debugLogs as DebugLoggerType;
  }
}
export default DebugLogger;
