import { SetServiceDependencies } from '../../infra/commonInitializer/types';
import bindAllMethods from '../../utils/bindAllMethods';
import { INavigationService } from '../navigationService';
import IRouteStageService from './IRouteStageService';
import * as T from './types';

export default class RouteStageService implements IRouteStageService {
  private _stages: T.RouteStageServiceInputType[] = [];
  private _navigationService: INavigationService;

  constructor(dependencies: T.RouteStageServiceDependenciesInputType) {
    this._stages = dependencies?.stages || [];
    bindAllMethods(this);
  }

  setDependencies(dependencies: SetServiceDependencies): void {
    this._navigationService = dependencies?.services?.navigationService;
  }
  /**
   * Check if the passed path is a stage path.
   *
   * @param {string} [path] - Path to be checked. If not provided, the current navigation pathname will be used.
   * @returns {boolean} - Returns `true` if the path is a stage path, otherwise `false`.
   */
  isStagePath(path?: string): boolean {
    const pathToCheck = path
      ? path
      : this?._navigationService?.location?.pathname || '';

    return this._stages.some((stage) => {
      return stage?.path == pathToCheck;
    });
  }

  getStageByPath(path: string): T.RouteStageServiceInputType | undefined {
    const pathToCheck = path;
    return this._stages.find((stage) => stage?.path === pathToCheck);
  }

  getStages(): T.RouteStageServiceInputType[] {
    return this._stages;
  }
}
