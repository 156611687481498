import { ISupportSessionService } from '../../services/supportSession';
import { TokenType } from '../../interface/types';
import {
  AuthContextEnum,
  IAuthTokenService
} from '../../services/authTokenService';
import { ISessionService } from '../../services/session';
import { getCookie } from '../../utils/cookies';

export type SetFirstApplicationTokenParametersType = {
  sessionService: ISessionService;
  authTokenService: IAuthTokenService;
  supportSessionService: ISupportSessionService;
  shouldRefreshToken?: boolean;
};

export default class SetFirstApplicationToken {
  private _sessionService: ISessionService;
  private _authTokenService: IAuthTokenService;
  private _supportSessionService: ISupportSessionService;
  private readonly _shouldRefreshToken: boolean;

  constructor({
    sessionService,
    authTokenService,
    supportSessionService,
    shouldRefreshToken = false
  }: SetFirstApplicationTokenParametersType) {
    this._sessionService = sessionService;
    this._authTokenService = authTokenService;
    this._supportSessionService = supportSessionService;
    this._shouldRefreshToken = shouldRefreshToken;
  }

  public async init(): Promise<void> {
    return this._setFirstWebToken();
  }

  private async _setFirstWebToken(): Promise<void> {
    // Check for stratus-id token or support session
    if (
      !this._sessionService.getIdToken() &&
      !this._supportSessionService.isSupportSession()
    )
      return;

    // Check if the auth token service already has a token
    if (this._authTokenService.getToken()?.token) return;

    if (this._shouldRefreshToken) {
      await this._setAuthTokenByRefresh();
    } else {
      this._setAuthTokenByCookie();
    }
  }

  private _setAuthTokenByCookie(): void {
    const initialToken =
      getCookie(TokenType.stratusAccessToken, false) ||
      getCookie(TokenType.deprecatedstratusAccessToken, false);
    !!initialToken &&
      this._authTokenService.setToken(initialToken, AuthContextEnum.tenantless);
  }

  private async _setAuthTokenByRefresh(): Promise<void> {
    try {
      await this._sessionService.refresh();
    } catch (error) {
      console.error('Error during initial token refresh:', error);
    }
  }
}
