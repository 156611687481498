import { PortalOnboardingType } from '../../../services/tenantHandler/strategy/external-types';
import TenantHandlerInterfaceV1 from './TenantHandlerInterfaceV1';
import * as T from './types';

let tenantHandlerInterfaceV1: T.TenantHandlerInterfaceType;

export default async function initializeTenantHandlerInterfaceSingleton(): Promise<T.TenantHandlerInterfaceType> {
  if (tenantHandlerInterfaceV1) return tenantHandlerInterfaceV1;

  tenantHandlerInterfaceV1 = new TenantHandlerInterfaceV1();

  return tenantHandlerInterfaceV1;
}

export function createNoopTenantHandlerInterface(): T.TenantHandlerInterfaceType {
  return {
    isEnabled: function (): boolean {
      console.debug('Function not implemented.');
      return false;
    },
    getCurrentContext: function (): T.AuthContextEnum {
      console.debug('Function not implemented.');
      return T.AuthContextEnum.tenantless;
    },
    getTenantData: function (): T.TenantVisualizationType {
      console.debug('Function not implemented.');
      return { id: '1', name: '' };
    },
    getTenantId: function (): string {
      console.debug('Function not implemented.');
      return '';
    },
    setTenant: function (): Promise<boolean> {
      console.debug('Function not implemented.');
      return Promise.resolve(true);
    },
    getTenantById: function (): Promise<T.TenantVisualizationType> {
      console.debug('Function not implemented.');
      return Promise.resolve({ id: '1', name: '' });
    },
    getTenantList: function (): Promise<T.TenantVisualizationType[]> {
      console.debug('Function not implemented.');
      return Promise.resolve([]);
    },
    getPaginatedTenantList: function (): Promise<any> {
      console.debug('Function not implemented.');
      return Promise.resolve();
    },
    getNextUnproccessedTenant: function (): any {
      console.debug('Function not implemented.');
      return {};
    },
    onTenantChange: function (): () => void {
      console.debug('Function not implemented.');
      return () => {};
    },
    getPortalOnboardingList: function (): PortalOnboardingType[] {
      console.debug('Function not implemented.');
      return [];
    }
  };
}
