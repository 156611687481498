import { GenericLogMethod, GenericLogMethodArgs } from '../types';
import { getNativeLogLevel } from './getNativeLogLevel';
import { isValidExpressions } from './isValidExpressions';
import { _getLoggerOptions } from './LocalOptions';

export const flatMessage = (log: Record<string, any>) => {
  const _metaDataArrayLog = Object.values({ ...log, data: '' }).filter(Boolean);
  return [..._metaDataArrayLog, ...log.data];
};

/**
 * Generic log method to handle logging with various parameters and conditions.
 * @param args - The arguments for the log method.
 * @returns The log method.
 */
export function genericLogMethod({
  params: paramsName,
  loggerProps,
  rootPrintOnlyValues = false,
  type,
  ...defaultValues
}: GenericLogMethodArgs): GenericLogMethod {
  const { jweb, shouldCallBrowserLog, shouldCallNativeLog } = loggerProps;

  // We will fill the params to be shown, following the order below:

  // 1. Here. we get the values set in initialization time, like the default values
  // as 'preffixLog' and 'portalName'.
  const defaultParamsList = {};
  paramsName.forEach((param) => {
    if (defaultValues[param]) {
      defaultParamsList[param] = defaultValues[param];
    }
  });

  // Creating the log printer based on some closure variables.
  const logPrinter = (
    log: Record<string, any>,
    printOnlyValues: boolean = false
  ): void => {
    const level = log.level || log.type;
    if (shouldCallNativeLog) {
      const logLevel = getNativeLogLevel(level);
      const message = JSON.stringify(log);
      const nativeLogArgs = {
        logLevel,
        message,
        tag: ''
      };
      jweb?.Plugins?.Console?.log?.(nativeLogArgs);
    }

    if (shouldCallBrowserLog) {
      if (printOnlyValues) {
        console[level](...flatMessage(log));
      } else {
        console[level](log);
      }
    }
  };

  // Return the log method. Below, we will retrieve the parameters in execution time.
  return function (...userParamsValues: any[]) {
    // Here we get variables that could change in runtime as timestamp and method name.
    const timestamp = new Date().toISOString();

    // Also we always get the logger options from the local storage
    const {
      allowedTypes,
      allowedExpressions,
      enable,
      printOnlyValues,
      forceAllowedExpressionsForErrors
    } = _getLoggerOptions();

    // If the logger is disabled, we don't log anything
    if (!enable) {
      return;
    }

    const runtimeParamList = {};
    const customUserParams = userParamsValues;

    customUserParams.forEach((paramObj) => {
      paramsName.forEach((param) => {
        if (paramObj && paramObj[param]) {
          runtimeParamList[param] = paramObj[param];

          // If the param is an Error, we don't remove any data from it
          if (!(paramObj instanceof Error)) delete paramObj[param];
        }
      });
    });

    // Filter out empty objects from customUserParams
    /*customUserParams = customUserParams.filter(
      (paramObj) => paramObj && Object.keys(paramObj).length > 0
    );*/

    // Here we create the message
    const log = {
      timestamp,
      ...defaultParamsList,
      ...runtimeParamList,
      data: customUserParams
    };

    try {
      // Let's check if this log is valid to be printed
      if (
        allowedTypes[type] &&
        isValidExpressions(
          type,
          forceAllowedExpressionsForErrors,
          allowedExpressions || [],
          log
        )
      ) {
        // Finally, here we print the message on the right environment
        logPrinter(log, printOnlyValues || rootPrintOnlyValues);
      }
    } catch (err) {
      if (allowedTypes.error) {
        const withPreffixLog = loggerProps?.preffixLog
          ? loggerProps?.preffixLog + ' = '
          : '';
        console.error(withPreffixLog + err.toString());
      }
    }
  };
}
