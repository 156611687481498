import bindAllMethods from '../../utils/bindAllMethods';
import {
  LoggerInputType,
  SetServiceDependencies
} from '../../infra/commonInitializer/types';
import IApplicationService from '../applicationService/IApplicationService';
import { getJWeb } from '../JWeb';
import { Stack } from '../../types/stratus';
import { LevelLogProps } from './types';
import { shouldCallLogs } from './utils/shouldCallLogs';
import * as T from './types';
import {
  _removeLocalOptions,
  _setLocalOptions,
  _getLoggerOptions
} from './utils/LocalOptions';
import ILoggerService from './ILoggerService';
import {
  DebugLogger,
  ErrorLogger,
  InfoLogger,
  WarnLogger
} from './loggerTypes';
import * as JshellLogger from './createBareboneLogger';
import {
  DebugLoggerType,
  ErrorLoggerType,
  LogLoggerType,
  WarnLoggerType
} from './Dictionaries/types/types';

class LoggerService implements ILoggerService {
  public infoLogger: LogLoggerType;
  public debugLogger: DebugLoggerType;
  public warnLogger: WarnLoggerType;
  public errorLogger: ErrorLoggerType;

  private _enabled: boolean;
  private _isNative: boolean;
  private _applicationService: IApplicationService;
  private _disableBrowserConsoleOnNative: boolean;

  constructor(loggerProps: LoggerInputType) {
    // Setting the values from Manifest configuration
    this._enabled = loggerProps?.enable ?? true;
    this._isNative = loggerProps?.isNative ?? false;
    this._disableBrowserConsoleOnNative =
      loggerProps?.disableBrowserConsoleOnNative ?? false;
  }

  public setDependencies({ services }: SetServiceDependencies): void {
    const { applicationService } = services;
    this._applicationService = applicationService;

    bindAllMethods(this);
  }

  public setLocalOptions: T.SetLocalOptionsType = (options) => {
    _setLocalOptions(options);
  };

  public removeLocalOptions: T.RemoveLocalOptionsType = () => {
    _removeLocalOptions();
  };

  public createLoggerInstance: T.CreateLoggerInstanceType = (
    levelLoggerProps?: LevelLogProps
  ): T.TypedOutputLoggerInstanceType => {
    // Setting default values
    const finalLoggerProps = {
      shouldCallNativeLog: true,
      shouldCallBrowserLog: true,

      // Setting the default values from the ApplicationService
      appName: this._applicationService?.getAppName(),
      applicationContext: this._applicationService?.getApplicationContext(),
      envStack: Stack[this._applicationService?.getPortalStack()],
      authenticationProvider:
        this._applicationService?.getAuthenticationProvider(),
      ...levelLoggerProps
    };

    // If the preffixLog is set, we will use it as the serviceName
    // We are keeping the preffixLog for backward compatibility
    if (levelLoggerProps.preffixLog && !levelLoggerProps.serviceName) {
      levelLoggerProps.serviceName = levelLoggerProps.preffixLog;
      delete levelLoggerProps.preffixLog;
    }

    return {
      log: new InfoLogger(finalLoggerProps).getLogs(),
      debug: new DebugLogger(finalLoggerProps).getLogs(),
      warn: new WarnLogger(finalLoggerProps).getLogs(),
      error: new ErrorLogger(finalLoggerProps).getLogs()
    };
  };

  async init(): Promise<void> {
    const jweb = await getJWeb();

    const { disableBrowserConsoleOnNative } = _getLoggerOptions() || {
      disableBrowserConsoleOnNative: this._disableBrowserConsoleOnNative
    };

    const { shouldCallNativeLog, shouldCallBrowserLog } = shouldCallLogs(
      this._isNative,
      jweb,
      disableBrowserConsoleOnNative
    );

    //TODO: Add App Version props to the logger
    const levelLoggerProps: LevelLogProps = {
      jweb,
      shouldCallNativeLog,
      shouldCallBrowserLog,
      serviceName: '@clientos/commons-northbound-api'
    };

    if (this._enabled) {
      const loggers = this.createLoggerInstance(levelLoggerProps);
      // Updating the internalLogger with the new information;
      JshellLogger.updateBareboneLogger(loggers);
      this.infoLogger = loggers.log;
      this.debugLogger = loggers.debug;
      this.warnLogger = loggers.warn;
      this.errorLogger = loggers.error;
    }
  }
}

export default LoggerService;
