import { JWebType } from '../../JWeb/types';

export const shouldCallLogs = (
  isNative: boolean,
  jweb: JWebType,
  disableBrowserConsoleOnNative: boolean
): { shouldCallNativeLog: boolean; shouldCallBrowserLog: boolean } => {
  const isNativeLogPluginMissing = isNative && !jweb?.Plugins?.Console;
  const shouldCallNativeLog = Boolean(isNative && jweb?.Plugins?.Console);
  const shouldCallBrowserLog =
    !isNative || isNativeLogPluginMissing || !disableBrowserConsoleOnNative;

  if (isNativeLogPluginMissing) {
    console.warn(
      'JWeb Console Plugin not found. Using default console instead. You may miss this log on some native apps.'
    );
  }

  return { shouldCallNativeLog, shouldCallBrowserLog };
};
