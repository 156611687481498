import bindAllMethods from '../../../utils/bindAllMethods';
import { IEventService } from '../../../services/eventService';
import { INavigationService } from '../../../services/navigationService';
import { LOGIN_PATHNAME } from '../../../services/session/constants';
import { InitialNavigationStateRepository } from './InitialNavigationStateRepository';
import { ITenantHandlerService } from '../../../services/tenantHandler';
import { ISessionService } from '../../../services/session';

export type RefreshWhenNativeAuthStatusChangedParams = {
  eventService: IEventService;
  navigationService: INavigationService;
  initialNavigationStateRepository: InitialNavigationStateRepository;
  tenantHandlerService: ITenantHandlerService;
  sessionService: ISessionService;
};

export default class RefreshWhenNativeAuthStatusChanged {
  private _eventService: IEventService;
  private _navigationService: INavigationService;
  private _tenantHandlerService: ITenantHandlerService;
  private _sessionService: ISessionService;
  private _initialNavigationStateRepository: InitialNavigationStateRepository;
  private AuthPluginLoginChangeEventName =
    'com.hp.jarvis.auth.event.login.change';

  constructor({
    eventService,
    navigationService,
    initialNavigationStateRepository,
    tenantHandlerService,
    sessionService
  }: RefreshWhenNativeAuthStatusChangedParams) {
    bindAllMethods(this);
    this._eventService = eventService;
    this._navigationService = navigationService;
    this._initialNavigationStateRepository = initialNavigationStateRepository;
    this._tenantHandlerService = tenantHandlerService;
    this._sessionService = sessionService;
  }

  public init = async (): Promise<void> => {
    this._consumeSavedNavigationState();

    this._eventService.subscribe(
      this.AuthPluginLoginChangeEventName,
      (event) => {
        const { eventData } = event || {};
        const currentPath = this._navigationService.location.pathname;

        const isLoggedIn = this._sessionService.isLoggedIn();

        const hasUserLoggedInOrLoggedoutFromJWeb =
          !eventData?.currentAccount?.emailAddress ||
          !eventData?.previousAccount?.emailAddress;

        const hasUserLoggedInOrLoggedoutFromShell =
          (!isLoggedIn && eventData?.currentAccount?.emailAddress) ||
          (isLoggedIn && !eventData?.currentAccount?.emailAddress);

        if (
          currentPath !== LOGIN_PATHNAME &&
          (hasUserLoggedInOrLoggedoutFromJWeb ||
            hasUserLoggedInOrLoggedoutFromShell)
        ) {
          this._reloadPageKeepingState();
          return;
        }

        const oldTenant = eventData?.previousAccount?.tenantId;
        const newTenant = eventData?.currentAccount?.tenantId;

        const hasTenantChanged = newTenant !== oldTenant;

        if (newTenant && hasTenantChanged) {
          this._tenantHandlerService.setTenant(
            newTenant,
            this._tenantHandlerService.START_TENANT_LEVEL,
            { reload: false }
          );
        }
      }
    );
  };

  private _reloadPageKeepingState = (): void => {
    const currentState = this._navigationService.location.state;
    const currentPath = this._navigationService.location.pathname;
    this._initialNavigationStateRepository.save(currentPath, currentState);
    window.location.reload();
  };

  private _consumeSavedNavigationState = (): void => {
    const currentPath = this._navigationService.location.pathname;
    const savedState =
      this._initialNavigationStateRepository.findOne(currentPath);
    if (savedState) {
      this._initialNavigationStateRepository.clear();
      this._navigationService.replace({
        ...this._navigationService.location,
        state: savedState
      });
    }
  };
}
