import { getStratusAccessToken } from '../../utils/getStratusAccessToken';
import { getTokenContextBasedOnTypeName } from '../../utils/tokenUtils/getTokenContextBasedOnTypeName';
import { decodeJWTPayload } from '../../utils/tokenUtils/JWTUtils';
import { internalLogger } from '../v1/logger';
import { getTenantTokenWithoutStore } from '../../clients/shell/session';
import { tenantIdName } from '../../config/constants';
import { AuthContextEnum } from '../../services/authTokenService';
import authContextEnumToTenantLevel from '../../services/authTokenService/utils/authContextEnumToTenantLevel';
import { ISessionService } from '../../services/session';
import { PromiseReturnType } from '../../types/typeHandlers';
import { getCookie } from '../../utils/cookies';
import { TokenType, UserContextEnum } from '../types';
import ITenantHandlerService from '../../services/tenantHandler/ITenantHandlerService';
import { LoginInputType } from '../../infra/commonInitializer/types';
import { ILoginService } from '../../services/session/loginService';

let _sessionService: ISessionService;
let _tenantHandlerService: ITenantHandlerService;
let _loginService: ILoginService;

export default function authProvider(
  loginInput?: LoginInputType,
  tokenTypeName: TokenType = TokenType.stratusAccessToken,
  tenantId = ''
) {
  return {
    _setSessionService: (sessionService: ISessionService): void => {
      _sessionService = sessionService;
    },

    _setTenantHandlerService: (
      tenantHandlerService: ITenantHandlerService
    ): void => {
      _tenantHandlerService = tenantHandlerService;
    },

    _setLoginService: (loginService: ILoginService): void => {
      _loginService = loginService;
    },

    getAccessToken: async (forceRefresh?: boolean): Promise<string> => {
      if (tenantId) {
        const authProviderTokenContext =
          getTokenContextBasedOnTypeName(tokenTypeName);
        const targetContext =
          authProviderTokenContext === UserContextEnum.organization
            ? UserContextEnum.customer
            : UserContextEnum.organization;

        const { stratusToken } = await getTenantTokenWithoutStore(
          targetContext,
          tenantId,
          getStratusAccessToken(tokenTypeName)
        );
        return stratusToken;
      }

      if (forceRefresh) {
        try {
          await _sessionService.refresh({
            tenantsIdMap: _tenantHandlerService.getTenantIdsMap()
          });
        } catch (error) {
          internalLogger?.error(error);
          const { enabled: isLoginEnable = true } = loginInput || {};

          if (isLoginEnable) {
            await _sessionService.logout();
          }
        }
      }
      return getStratusAccessToken(tokenTypeName);
    },
    getIDToken: async (): Promise<string> => {
      return _sessionService.getIdToken();
    },

    onTokenExchangeRequired: async (
      userType: UserContextEnum = UserContextEnum.organization,
      tenantId?: string
    ): Promise<void> => {
      await _sessionService.refresh();

      const _tenantId = tenantId || getCookie(tenantIdName);
      let authContext;
      switch (userType) {
        case UserContextEnum.customer:
          authContext = AuthContextEnum.subtenant;
          break;
        default:
          authContext = AuthContextEnum.tenant;
          break;
      }

      const level = authContextEnumToTenantLevel(authContext);
      await _tenantHandlerService.setTenant(_tenantId, level, {
        reload: false
      });
    },

    forceLogin: async () => {
      return new Promise(() => {
        _loginService.redirectToLogin({ forceLogin: true });
      });
    },

    getDecodedUserStratusJWTPayload: (): any => {
      const userToken = getStratusAccessToken(tokenTypeName);

      if (!userToken) return {};

      return decodeJWTPayload(userToken);
    },

    createOrglessAuthProvider: (tenantId = ''): any => {
      return Object.assign(
        {},
        authProvider(loginInput, TokenType.orglessToken, tenantId)
      );
    },

    createOrgedAuthProvider: (tenantId = ''): any => {
      return Object.assign(
        {},
        authProvider(loginInput, TokenType.orgedToken, tenantId)
      );
    },

    createAuthProviderByUserContextEnum: (
      userContext: UserContextEnum
    ): any => {
      let tokenType: TokenType;
      switch (userContext) {
        case UserContextEnum.customer:
          tokenType = TokenType.stratusAccessToken;
          break;
        case UserContextEnum.organization:
          tokenType = TokenType.orgedToken;
          break;
        case UserContextEnum.orgless:
          tokenType = TokenType.orglessToken;
          break;
        default:
          tokenType = TokenType.stratusAccessToken;
      }
      return Object.assign({}, authProvider(loginInput, tokenType));
    }
  };
}

export type AuthProviderV2Type = PromiseReturnType<typeof authProvider>;
