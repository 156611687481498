import { ApplicationContextEnum } from '../../../services/applicationService/enums';
import { Stack } from '../../../types/stratus';
import AppInterfaceV1, { AppInterfaceV1Param } from './AppInterfaceV1';
import { AppInterfaceV1Type } from './types';

let appInterfaceV1: AppInterfaceV1;

function _createAppInterfaceV1(options: AppInterfaceV1Param) {
  const { applicationService } = options;

  return new AppInterfaceV1({
    applicationService
  });
}

export function getAppInterfaceV1(): AppInterfaceV1Type {
  return appInterfaceV1.getInterface();
}

export default async function initializeAppInterfaceV1(
  options: AppInterfaceV1Param
): Promise<AppInterfaceV1Type> {
  if (appInterfaceV1) return appInterfaceV1.getInterface();
  appInterfaceV1 = _createAppInterfaceV1(options);

  return appInterfaceV1.getInterface();
}

/**
 * Create a bare-bones interface without any implementation. The term No-op
 * (NOP) stands for “no operation”.
 * @returns FeatureFlagInterfaceV1Type
 */
export function createNoopAppInterface(): AppInterfaceV1Type {
  return {
    getClientId: function (): string {
      console.debug('Function not implemented.');
      return '';
    },
    getAppName: function (): string {
      console.debug('Function not implemented.');
      return '';
    },
    getPortalStack: function (): Stack {
      console.debug('Function not implemented.');
      return Stack.dev;
    },
    getAuthStack: function (): Stack {
      console.debug('Function not implemented.');
      return Stack.dev;
    },
    getAppInstanceId: async function (): Promise<string> {
      console.debug('Function not implemented.');
      return '';
    },
    getAppVersion: async function (): Promise<string> {
      console.debug('Function not implemented.');
      return '';
    },

    getPlatform: async function (): Promise<string> {
      console.debug('Function not implemented.');
      return '';
    },

    getApplicationContext: function (): ApplicationContextEnum {
      console.debug('Function not implemented.');
      return ApplicationContextEnum.WEB_PORTAL;
    }
  };
}
