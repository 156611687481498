import {
  GetProviderListParam,
  GetProviderListResponseType
} from '../../../clients/shell/provider';
import { SetServiceDependencies } from '../../../infra/commonInitializer/types';
import ILoginService, {
  IsLoggedinPathPropsType,
  IsLoginPathPropsType,
  RedirectToLoginParams
} from './ILoginService';
import { CheckPathPropsType, GenerateAuthenticationUrlParams } from './types';
import matchPath from '../../../utils/matchPath';
import { INavigationService } from '../../../services/navigationService';
import { LOGGEDIN_PATHNAME, LOGIN_PATHNAME } from '../constants';
import { _getLoginPath } from '../utils/getLoginPath';
import bindAllMethods from '../../../utils/bindAllMethods';

export default abstract class BaseLoginService implements ILoginService {
  protected _navigationService: INavigationService;

  setDependencies(options: SetServiceDependencies): void {
    this._navigationService = options?.services?.navigationService;
    bindAllMethods(this);
  }

  private checkPath(props: CheckPathPropsType): boolean {
    let pathToCompare =
      props?.pathToCompare ||
      this?._navigationService?.location?.pathname ||
      '';
    if (!pathToCompare.startsWith('/')) pathToCompare = '/' + pathToCompare;
    return matchPath(props?.shellInternalPath, {
      exact: props?.exact,
      pathToCompare
    });
  }

  isLoginPath(options?: IsLoginPathPropsType): boolean {
    return this.checkPath({
      pathToCompare: options?.pathToCheck,
      shellInternalPath: LOGIN_PATHNAME,
      exact: options?.exact
    });
  }

  isLoggedinPath(options?: IsLoggedinPathPropsType): boolean {
    return this.checkPath({
      pathToCompare: options?.pathToCheck,
      shellInternalPath: LOGGEDIN_PATHNAME,
      exact: options?.exact
    });
  }

  getLoginPath(params?: RedirectToLoginParams): string {
    return _getLoginPath(params);
  }

  /**
   *  @deprecated This method uses the old version of redirect.
   * */
  redirectToLogin(params?: RedirectToLoginParams): void {
    // @TODO: add a validation to use  _navigationService.redirect() instead of window location
    const loginPath = _getLoginPath(params);
    window.location.href = loginPath;
  }

  // This method is to be used in before init behaviors, because some services have not been initialized yet
  static isInLoggedinPath(): boolean {
    const currentPath = window.location.pathname;
    return currentPath?.includes(LOGGEDIN_PATHNAME);
  }

  // This method is to be used in before init behaviors, because some services have not been initialized yet
  static isInLoginPath(): boolean {
    const currentPath = window.location.pathname;
    return currentPath?.includes(LOGIN_PATHNAME);
  }

  abstract getProviderList(
    options?: GetProviderListParam
  ): Promise<GetProviderListResponseType>;
  abstract generateAuthenticationUrl(
    options: GenerateAuthenticationUrlParams
  ): Promise<string>;
}
